<template>
  <div class="equipment-edit-container">
    <div class="operate">
      <dsn-button
          size="medium"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save
          @click.native="checkAdd(['equipmentFormRef'])" size="medium">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="medium"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['equipmentFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="equipmentForm"
          ref="equipmentFormRef"
          :inline="true"
          :rules="equipmentFormRules"
          class="equipment-main-form"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipments.fields.mainCategory')" prop="equipmentMainCategoryId"
                          :label-width="labelWidth">
              <dsn-select
                  v-model.trim="equipmentForm.equipmentMainCategoryId"
                  filterable
                  placeholder="请选择大分类"
                  size="medium"
                  @change="onMainCategoryChange"
              >
                <el-option
                    v-for="item in mainCategoryList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </dsn-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipments.fields.middleCategory')" prop="equipmentCategoryId"
                          :label-width="labelWidth">
              <!--              <dsn-input-->
              <!--                  v-model.trim="equipmentForm.username"-->
              <!--                  placeholder="请输入主分类"-->
              <!--                  :disabled="isEditStation"-->
              <!--              ></dsn-input>-->

              <dsn-select
                  v-model.trim="equipmentForm.equipmentCategoryId"
                  filterable
                  placeholder="请选择中分类"
                  size="medium"
                  @change="onMiddleCategoryChange1"
              >
                <el-option
                    v-for="item in equipmentCategoryList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </dsn-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipment.fields.subcategory')" prop="equipmentSubcategoryId"
                          :label-width="labelWidth">
              <!--              <dsn-input-->
              <!--                  v-model.trim="equipmentForm.realname"-->
              <!--                  placeholder="请输入子分类"-->
              <!--              ></dsn-input>-->
              <dsn-select
                  v-model.trim="equipmentForm.equipmentSubcategoryId"
                  filterable
                  placeholder="请选择设备子分类"
                  size="medium"
				   @change="onSubcategorySelect"
              >
                <el-option
                    v-for="item in equipmentSubcategoryList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </dsn-select>
            </el-form-item>
          </el-col>
        </el-row>
		
		
		<el-row>
			<el-col :span="8">
				<el-form-item :label="this.$t('commbtn.maingear')" :label-width="labelWidth">
					<dsn-select v-model.trim="maingear" filterable
						:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('commbtn.maingear')"
						size="medium" @change="onmaingearSelect">
						<el-option v-for="item in MainProfileList" :key="item.id"
							:label="item.name + ' | ' + item.brand_name + ' | ' + item.model_type"
							:value="item.id"></el-option>
					</dsn-select>
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="this.$t('commbtn.addgear')" :label-width="labelWidth">
					<el-checkbox v-model="isNewGear"></el-checkbox>
				</el-form-item>
		
			</el-col>
		
		</el-row>
		
		
		
        <el-row>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipment.fields.name')" prop="name" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="equipmentForm.name"
                  placeholder="请输入设备名称"
				  :disabled="isNewGear==false"
              ></dsn-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipment.fields.brand')" prop="brandName" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="equipmentForm.brandName"
                  placeholder="请输入品牌"
				  :disabled="isNewGear==false"
              ></dsn-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipment.fields.modelType')" prop="modelType" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="equipmentForm.modelType"
                  placeholder="请输入规格型号"
				  :disabled="isNewGear==false"
              ></dsn-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
			
			<el-col :span="8">
			  <el-form-item :label="this.$t('equipment.fields.sno')" prop="sno" :label-width="labelWidth">
			    <dsn-input
			        v-model.trim="equipmentForm.sno"
			        placeholder="请输入机身编码"
			    ></dsn-input>
			  </el-form-item>
			</el-col>
			
          <el-col :span="8">
            <el-form-item :label="this.$t('equipment.fields.acquisitionDate')" prop="acquisitionDate"
                          :label-width="labelWidth">
              <!--              <dsn-input-->
              <!--                  v -model.trim="userForm.acquisitionDate"-->
              <!--                  placeholder="请输入性别"-->
              <!--              ></dsn-input>-->
              <el-date-picker
                  v-model.trim="equipmentForm.acquisitionDate"
                  type="datetime"
                  placeholder="选择入厂日期"
                  size="medium"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
		  
		  <el-col :span="8">
		    <el-form-item :label="this.$t('equipments.fields.factory')" prop="factoryName"
		                  :label-width="labelWidth">
		      <dsn-select
		          v-model.trim="equipmentForm.factoryName"
		          filterable
		          style="width:225px"
		          :placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.factory')"
		          size="medium"
		      >
		        <el-option
		            v-for="item in factoryList"
		            :key="item.value"
		            :label="item.label"
		            :value="item.value"
		        ></el-option>
		      </dsn-select>
		    </el-form-item>
		  </el-col>
		  
          
        </el-row>
		<el-row>
			<el-col :span="8">
			  <el-form-item :label="this.$t('equipments.fields.storage')" prop="storageId"
			                :label-width="labelWidth">
			    <!--              <storage-tree-select v-model="equipmentForm.storageId" :data="storageNodeList" :node-key="'id'" :props="{-->
			    <!--                children: 'children',-->
			    <!--                label: 'position'-->
			    <!--              }"/>-->
			    <el-tree-select ref="treeSelect" v-model="equipmentForm.storageId" :selectParams="selectParams"
			                    :treeParams="treeParams"
			                    :isSingle="true" v-on:node-click="onStorageNodeClick"/>
			
			  </el-form-item>
			</el-col>
			<el-col :span="8">
			  <el-form-item :label="this.$t('equipment.fields.condition')" prop="conditionId" :label-width="labelWidth">
			    <dsn-select
			        v-model.trim="equipmentForm.conditionId"
			        filterable
			        style="width:225px"
			        placeholder="请选择状态"
			        size="medium"
			        @change="onEquipmentConditionChange"
			    >
			      <el-option
			          v-for="item in conditionList"
			          :key="item.key"
			          :label="item.label"
			          :value="item.value"
			      ></el-option>
			    </dsn-select>
			  </el-form-item>
			</el-col>
			<el-col :span="8">
				

				
					<el-form-item :label="this.$t('equipments.fields.images')" :label-width="labelWidth" class="lastform">
						
						<el-upload action="#" class="upload-demo1" style="display: flex;" ref="uploadpic1" disabled :file-list="imageList" list-type="picture-card":auto-upload="true" >

									<div slot="file" slot-scope="{ file }" style="width: 100%;height: 100%;">
										
										<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							                <span class="el-upload-list__item-actions">
							                  <span
							                    class="el-upload-list__item-preview"
							                    @click="Previewpic1(file.url)"
							                  >
							                    <i class="el-icon-zoom-in"></i>
							                  </span>
							                  
							                  <span
							                    class="el-upload-list__item-delete"
							                    @click="removepic1(file)"
							                  >
							                    <i class="el-icon-delete"></i>
											</span>
										</span>
									</div>
							
									
							
						</el-upload>
						
						
						
						<!-- <div style="margin:0 10px 0 0;">
							<el-image v-if="imagePreview != null" style="width: 65px; height: 65px" :src="imagePreview" :preview-src-list="imageList">
								<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
							</el-image>
						</div> -->
						
						
						<el-upload class="upload-demo" style="display: flex;" ref="uploadpic" :auto-upload="true"
							:action="uploadFileURL" :on-change="fileChange" :before-upload="picBefore"
							:on-remove="filesremove" :on-success="filesSuccess" :file-list="fileList"
							list-type="picture-card" multiple>
							
							<i slot="default" class="el-icon-plus"></i>
									<div slot="file" slot-scope="{ file }" style="width: 100%;height: 100%;">
										
										<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							                <span class="el-upload-list__item-actions">
							                  <span
							                    class="el-upload-list__item-preview"
							                    @click="Previewpic(file)"
							                  >
							                    <i class="el-icon-zoom-in"></i>
							                  </span>
							                  
							                  <span
							                    class="el-upload-list__item-delete"
							                    @click="removepic(file)"
							                  >
							                    <i class="el-icon-delete"></i>
											</span>
										</span>
									</div>
							
			
							
						</el-upload>
						
						<el-dialog :visible.sync="dialogVisible">
								<img width="100%" :src="dialogImageUrl" alt="预览图片" />
							</el-dialog>
						
					</el-form-item>
					
				</el-col>
		</el-row>
          <el-col>
<!--            <el-form-item :label="this.$t('equipment.fields.sno')" prop="sno" :label-width="labelWidth">-->
<!--              <dsn-input-->
<!--                  v-model.trim="equipmentForm.sno"-->
<!--                  placeholder="请输入编号" :disabled="true"-->
<!--              ></dsn-input>-->
<!--            </el-form-item>-->

<!--            <el-form-item :label="this.$t('equipment.fields.sapNo')" prop="sapAssetNo" :label-width="labelWidth">-->
<!--              <dsn-input-->
<!--                  v-model.trim="equipmentForm.sapAssetNo"-->
<!--                  placeholder="请输入SAP 编号"-->
<!--              ></dsn-input>-->
<!--            </el-form-item>-->
          </el-col>
<!--            <el-form-item :label="this.$t('equipment.fields.assetNo')" prop="assetNo" :label-width="labelWidth">-->
<!--              <dsn-input-->
<!--                  v-model.trim="equipmentForm.assetNo"-->
<!--                  placeholder="请输入固定资产编号" :disabled="true"-->
<!--              ></dsn-input>-->
<!--            </el-form-item>-->



<!--            <el-form-item :label="this.$t('equipment.fields.runState')" prop="runningStatusId"-->
<!--                          :label-width="labelWidth">-->
<!--              <dsn-select-->
<!--                  v-model.trim="equipmentForm.runningStatusId"-->
<!--                  filterable-->
<!--                  style="width:225px"-->
<!--                  placeholder="请选择运行状态"-->
<!--                  size="medium"-->
<!--              >-->
<!--                <el-option-->
<!--                    v-for="item in runningSateList"-->
<!--                    :key="item.key"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value"-->
<!--                ></el-option>-->
<!--              </dsn-select>-->
<!--            </el-form-item>-->

            <!--            <el-form-item :label="this.$t('equipment.fields.dept')" prop="deptId" :label-width="labelWidth">-->
            <!--              <dsn-input-->
            <!--                  v-model.trim="equipmentForm.deptId"-->
            <!--                  placeholder="请选择使用部门"-->
            <!--              ></dsn-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item :label="this.$t('equipment.fields.user')" prop="userId" :label-width="labelWidth">-->
            <!--              <dsn-input-->
            <!--                  v-model.trim="equipmentForm.userId"-->
            <!--                  placeholder="选择使用人"-->
            <!--              ></dsn-input>-->
            <!--            </el-form-item>-->
            <!--          </el-col>-->

            <!--          <el-col>-->
            <!--            <el-form-item :label="this.$t('equipment.fields.directorDept')" prop="directorDeptId"-->
            <!--                          :label-width="labelWidth">-->
            <!--              <dsn-input-->
            <!--                  v-model.trim="equipmentForm.directorDeptId"-->
            <!--                  placeholder="请选择使用部门"-->
            <!--              ></dsn-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item :label="this.$t('equipment.fields.director')" prop="directorId" :label-width="labelWidth">-->
            <!--              <dsn-input-->
            <!--                  v-model.trim="equipmentForm.directorId"-->
            <!--                  placeholder="选择使用人"-->
            <!--              ></dsn-input>-->
            <!--            </el-form-item>-->
      </el-form>
    </div>
  </div>

</template>
<script>
import {updateEquipments,GetMainProfileList,InvalidAttachment} from "@/api/efms/equipments/equipments.api";
import {getBrandList} from "@/api/efms/equipments/equipment.brand.api";
import {getEquipmentCategoryList} from "@/api/efms/equipments/equipment.category.api";
import arrayToTree from "array-to-tree";
import {mapGetters,mapState} from "vuex";
import _ from "lodash";
import {getStorageList} from "@/api/efms/equipments/equipment.storage.api";
import StorageTreeSelect
  from "@/views/mes/basic_settings/equipments_position_ref_settings/components/storage-tree-select";

export default {
  name: 'EquipmentEdit',
  components: {StorageTreeSelect},
  data() {
    return {
      isEditStation: false, //是否可编辑站位
      equipmentForm: {
        id: null,
        equipmentMainCategoryId: null,
        equipmentMainCategoryName: null,
        equipmentCategoryId: null,
        equipmentSubcategoryId: null,
        assetNo: null,
        sapNo: null,
        name: null,
        modelType: null,
        acquisitionDate: null,
        userId: null,
        deptId: null,
        directorDeptId: null,
        directorId: null,
        conditionId: null,
        conditionDesc: null,
		sno:null,
		factoryName:null,
      },
	  isNewGear: false,
	  maingear: '',
	  MainProfileList: [],
	  
	  fileList: [],
	  uploadData: {},
	  attachList: [],
	  isFile: '',
	  
	  imagePreview:'',
	  imageList:[],
	  dialogImageUrl:'',
	  dialogVisible:false,
	  
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: '请选择'
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        'check-strictly': true,
        'default-expand-all': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          children: 'children',
          label: 'position',
          // disabled: 'disabled',
          value: 'id'
        }
      },
      labelWidth: "120px",
      categoryFullList: [],
      categoryFullNodeList: [],
      equipmentCategoryList: [],
      equipmentSubcategoryList: [],
      conditionList: [],
      mainDeptList: [],
      brandList: [],
      storageNodeList: [],
      storageFullList: [],
      mainCategoryList: [],
      runningSateList: [
        {
          label: '开机',
          value: "1",
        },
        {
          label: '关机',
          value: "0",
        }, {
          label: '其他',
          value: "-1",
        }
      ],
	  factoryList: [
	    {
	      label: '西丽工厂',
	      value: '西丽工厂'
	    },
		{
			label: '东莞工厂',
			value: '东莞工厂'
		},
	  ]
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.cloneList = _.cloneDeep(JSON.parse(JSON.stringify(this.equipmentList)));
    this.equipmentForm =
        this.cloneList[0] ||
        JSON.parse(localStorage.getItem("equipmentList"))[0];
		console.log(this.equipmentForm);
	if (this.equipmentForm.imgIds != null) {
		let imageUrl = this.convertEquipmentImage(this.equipmentForm.imgIds);
		if (imageUrl != null && imageUrl.length > 0) {
			this.imagePreview=imageUrl[0].url;
			imageUrl.forEach((obj,objindex)=>{
				this.imageList.push({name:obj.name,url:obj.url})
			})
			
			// this.imageList=imageUrl;
			// console.log('imageUrl',imageUrl);
			// Object.assign(this.equipmentForm, {
			// 	imagePreview: imageUrl[0],
			// 	imageList: imageUrl
			// });
		}
	}
    this.initSelectData();
	this.GetMainProfileList();
  },
  activated() {
    this.initSelectData();
	this.GetMainProfileList();
  },
  computed: mapState({
  
  
  
  
  
  			uploadFileURL() {
				return 'https://cpm.deartsz.com/api/Utils/UploadAttachment?biz_type=equipment&id='+this.equipmentForm.id;
  				// return `https://cpm.deartsz.com/api/equipments/${this.equipmentForm.id}/uploadfiles`;
  				// return "https://cpm.deartsz.com/api/equipments/"+this.getAssetNo+"/uploadfiles"
  				// return uploadfilesapi(this.getAssetNo);
  			},
			
			...mapGetters(["equipmentList"]),
			equipmentFormRules: function () {
			  let that = this
			  return {
				equipmentCategoryId: [{
					required: true,
					message: "请输入中分类",
					trigger: "change"
				}],
				equipmentSubcategoryId: [{
					required: true,
					message: "请输入小分类",
					trigger: "change"
				}],
			    brandId: [{required: false, message: "请选择品牌", trigger: "change"}],
			    name: [{required: true, message: "请输入设备名称", trigger: "change"}],
			    sno: [{
			      required: false,
			      message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.sno')}`,
			      trigger: "change"
			    }],
			    modelType: [{
			      required: false,
			      message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.modelType')}`,
			      trigger: "change"
			    }],
			    deptId: [{
			      required: false,
			      message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.dept')}`,
			      trigger: "change"
			    }],
			    userId: [{
			      required: false,
			      message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.user')}`,
			      trigger: "change"
			    }],
			    directorDeptId: [{
			      required: false,
			      message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.directorDept')}`,
			      trigger: "change"
			    }],
			    directorId: [{
			      required: false,
			      message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.director')}`,
			      trigger: "change"
			    }],
			    conditionId: [{
			      required: false,
			      message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.condition')}`,
			      trigger: "change"
			    }],
			  }
			}
  		}),
  // computed: {
  //   ...mapGetters(["equipmentList"]),
  //   equipmentFormRules: function () {
  //     let that = this
  //     return {
  //       equipmentCategoryId: [{required: true, message: "请输入账号", trigger: "change"}],
  //       equipmentSubcategoryId: [{required: true, message: "请输入用户姓名", trigger: "change"}],
  //       brandId: [{required: false, message: "请选择品牌", trigger: "change"}],
  //       name: [{required: true, message: "请输入设备名称", trigger: "change"}],
  //       sno: [{
  //         required: false,
  //         message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.sno')}`,
  //         trigger: "change"
  //       }],
  //       modelType: [{
  //         required: false,
  //         message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.modelType')}`,
  //         trigger: "change"
  //       }],
  //       deptId: [{
  //         required: false,
  //         message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.dept')}`,
  //         trigger: "change"
  //       }],
  //       userId: [{
  //         required: false,
  //         message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.user')}`,
  //         trigger: "change"
  //       }],
  //       directorDeptId: [{
  //         required: false,
  //         message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.directorDept')}`,
  //         trigger: "change"
  //       }],
  //       directorId: [{
  //         required: false,
  //         message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.director')}`,
  //         trigger: "change"
  //       }],
  //       conditionId: [{
  //         required: false,
  //         message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.condition')}`,
  //         trigger: "change"
  //       }],
  //     }
  //   }
  // },
  methods: {
	  onSubcategorySelect(value) {
	  
	  	this.GetMainProfileList();
	  },
	  removepic(file,fileList) {
	  	console.log(file);
	          let uploadFiles = this.$refs.uploadpic.uploadFiles
	          for (var i = 0; i < uploadFiles.length; i++) {
	            if (uploadFiles[i]['url'] == file.url) {
	              uploadFiles.splice(i, 1)
	            }
	          }
	  	},
		removepic1(file) {
			console.log(file);
			
			let postData = {
			  id: file.name,
			}
			InvalidAttachment(postData).then(result => {
				const {status, data, errMsg} = result;
				if (status === 200) {
				  this.notification().success({
					body: '删除成功',
				  });
				} else {
				  this.notification().error({
					body: errMsg,
				  });
				}

			  })
			
			
			
		        let uploadFiles = this.$refs.uploadpic1.uploadFiles
		        for (var i = 0; i < uploadFiles.length; i++) {
		          if (uploadFiles[i]['url'] == file.url) {
		            uploadFiles.splice(i, 1)
		          }
		        }
			},
	  	// 点击文件列表中已上传的文件时的钩子
	  	Previewpic(file) {
	          this.dialogImageUrl = file.url
	          this.dialogVisible = true
	  	},
		
		Previewpic1(file) {
			console.log('------------');
		      this.dialogImageUrl = file
		      this.dialogVisible = true
		},
		
	  convertEquipmentImage(imgIds) {
	  	let result = [];
	  	if (imgIds != null && imgIds.length > 0) {
	  		let temp = imgIds.map(x => {
	  			return {url:`https://cpm.deartsz.com/api/equipments/download/${x}`,name:x};
	  		});
	  		result = [...temp];
			console.log('result',result);
	  	}
	  	return result;
	  },
	  picBefore(file) {
	  	console.log(file)
	  	if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
	  		this.notification().error({
	  			body: this.$t('commmsg.picerr')
	  		});
	  		return false;
	  	}
	  	else{
	  		// this.$refs.uploadpic.action = this.uploadFileURL;
	  		// console.log(this.$refs.uploadpic);
	  		// this.$nextTick(()=>{
	  		     // this.$refs.uploadpic.submit();
	  		  // })
	  	// 	return new Promise((resolve) => {
	  	// 		 this.$nextTick(() => {
	  	// 		 this.uploadURL = "https://cpm.deartsz.com/api/equipments/"+this.getAssetNo+"/uploadfiles"; 
	  	// 		   resolve();
	  	// 		 })
	  	// 	 })
	  	}
	  
	  
	  },
	  fileChange(file, fileList) {
	  	console.log(file, fileList);
	  
	  	if (fileList.length > 5) {
	  		fileList.splice(0, 1);
	  	}
	  	this.isFile = file.raw;
	  	
	  	// this.$refs.uploadpic.action = this.uploadFileURL;
	  	// else{
	  	//  this.isFile = file.raw;
	  	// }
	  
	  },
	  filesSuccess(res, file, fileList) {
	  	console.log(res, file, fileList);
	  	this.attachList.push(file.response.data);
	  	this.attachList = this.attachList;
	  },
	  
	  filesremove(file, fileList) {
	  	// console.log(file, fileList);
	  	this.attachList = fileList.slice(-3);
	  },
	  GetMainProfileList() {
	  	const data = {
	  
	  		mainCategory_id: this.equipmentForm.equipmentMainCategoryId,
	  		subCategory_id: this.equipmentForm.equipmentSubcategoryId,
	  		category_id: this.equipmentForm.equipmentCategoryId,
	  
	  		isTree: false,
	  	};
	  	GetMainProfileList(data).then(response => {
	  		const {
	  			status,
	  			errMsg,
	  			data
	  		} = response;
	  		if (status === 200) {
				this.maingear = '';
	  			if (data != null && data.data.length > 0) {
	  				let dataList = data.data;
	  				if (dataList != null && dataList.length > 0) {
	  					this.MainProfileList = [...dataList];
	  				} else {
	  					this.MainProfileList = [];
	  				}
	  				this.$forceUpdate();
	  			} else {
	  				this.MainProfileList = [];
	  				this.$forceUpdate();
	  			}
	  		} else {
	  			this.notification().warn({
	  				body: errMsg
	  			});
	  		}
	  	});
	  },
	  onmaingearSelect(e) {
	  	console.log(e);
	  	this.MainProfileList.forEach(obj => {
	  		if (obj.id == e) {
				// this.onMiddleCategoryChange1(obj.equipment_category_id);
				
	  			this.equipmentForm.name = obj.name;
	  			this.equipmentForm.brandName = obj.brand_name;
	  			this.equipmentForm.modelType = obj.model_type;
				
				this.equipmentForm.equipmentMainCategoryId = obj.equipment_main_category_id;
				this.equipmentForm.equipmentMainCategoryName = obj.equipment_main_category_name;
				
				this.equipmentForm.equipmentCategoryId = obj.equipment_category_id;
				this.equipmentForm.equipmentCategoryName = obj.equipment_category_name;
				
				this.equipmentForm.equipmentSubcategoryId = obj.equipment_subcategory_id;
				this.equipmentForm.equipmentSubcategoryName = obj.equipment_subcategory_name;
				
				this.maingear = obj.name + ' | ' + obj.brand_name + ' | ' + obj.model_type
	  		}
	  	})
	  },
    handleBack() {
      this.$router.pushAndClear(
          {name: "equipmentList"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        console.info(count)
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      const postData = {};
      console.info("handleDataSave")
      postData['id'] = this.equipmentForm.id
      postData['equipmentMainCategoryId'] = this.equipmentForm.equipmentMainCategoryId
      postData['equipmentMainCategoryName'] = this.equipmentForm.equipmentMainCategoryName
      postData['equipmentCategoryId'] = this.equipmentForm.equipmentCategoryId
      postData['equipmentSubcategoryId'] = this.equipmentForm.equipmentSubcategoryId
      if (postData['equipmentCategoryId'] != null) {
        let targetList = this.equipmentCategoryList.filter(s => s.value === postData['equipmentCategoryId']);
        if (targetList != null && targetList.length > 0) {
          postData['equipmentCategoryName'] = targetList[0].label
        }
      }
      if (postData['equipmentSubcategoryId'] != null) {
        let targetList = this.equipmentSubcategoryList.filter(s => s.value === postData['equipmentSubcategoryId']);
        if (targetList != null && targetList.length > 0) {
          postData['equipmentSubcategoryName'] = targetList[0].label
        }
      }
      postData['assetNo'] = this.equipmentForm.assetNo
      postData['factoryName'] = this.equipmentForm.factoryName
      postData['sapAssetNo'] = this.equipmentForm.sapAssetNo
      postData['runningStatusId'] = this.equipmentForm.runningStatusId
      if (postData['runningStatusId'] != null) {
        let targetList = this.runningSateList.filter(s => s.value === postData['runningStatusId'])
        if (targetList != null && targetList.length > 0) {
          postData['runningStatusDesc'] = targetList[0].label
        }
      }
      postData['name'] = this.equipmentForm.name
      postData['modelType'] = this.equipmentForm.modelType
      postData['brandName'] = this.equipmentForm.brandName
      // postData['brandId'] = this.equipmentForm.brandId
      postData['acquisitionDate'] = this.equipmentForm.acquisitionDate
	  postData['sno'] = this.equipmentForm.sno
      postData['userId'] = this.equipmentForm.userId
      postData['deptId'] = this.equipmentForm.deptId
      postData['directorId'] = this.equipmentForm.directorId
      postData['directorDeptId'] = this.equipmentForm.directorDeptId
      postData['conditionId'] = this.equipmentForm.conditionId
      postData['conditionDesc'] = this.equipmentForm.conditionDesc
      postData['storageId'] = this.equipmentForm.storageId
	  
	  // postData['profile_id'] = this.equipmentForm.profile_id
	  postData['profile_id'] = this.isNewGear == true?0:this.equipmentForm.profile_id
	  // profile_id
	  
	  
      if (this.equipmentForm.storageId != null && this.storageFullList.length > 0) {
        let targetList = this.storageFullList.filter(s => s.id === this.equipmentForm.storageId)
        if (targetList != null && targetList.length === 1) {
          postData['storageName'] = targetList[0].equipmentStorageName
        }
      }
	  console.info("this.equipmentForm",this.equipmentForm)
	  console.info("postData",postData)
	  var copyObject = Object.assign(this.equipmentForm,postData)
	  console.info("copyObject",copyObject)
      // updateEquipments(postData).then(result => {
	  // updateEquipments(this.equipmentForm).then(result => {
	  updateEquipments(copyObject).then(result => {
        const {status, data, errMsg} = result;
        if (status === 200) {
          this.notification().success({
            body: `${this.$t('commmsg.addSuccess')}`,
          });
          this.handleBack();
        } else {
          this.notification().error({
            body: errMsg,
          });
        }

      })
    },
    handleReset(formName) {
  //     if (this.operateType === "edit") {
  //       formName.forEach((element) => {
  //         this.$refs[element].resetFields();
  //       });
		
		// this.maingear = '';
  //     }
	  
	  this.maingear = '';
	  this.equipmentForm.equipmentMainCategoryId = null;
	  
	  this.equipmentForm.equipmentMainCategoryId = null;
	  this.equipmentForm.equipmentMainCategoryName = null;
	  this.equipmentForm.equipmentCategoryId = null;
	  this.equipmentForm.equipmentCategoryName = null;
	  this.equipmentForm.equipmentSubcategoryId = null;
	  this.equipmentForm.equipmentSubcategoryName = null;
	  this.equipmentForm.assetNo = null;
	  this.equipmentForm.sapAssetNo = null;
	  this.equipmentForm.name = null;
	  this.equipmentForm.modelType = null;
	  this.equipmentForm.acquisitionDate = null;
	  this.equipmentForm.userId = null;
	  this.equipmentForm.deptId = null;
	  this.equipmentForm.directorDeptId = null;
	  this.equipmentForm.directorId = null;
	  this.equipmentForm.conditionId = null;
	  this.equipmentForm.conditionDesc = null;
	  this.equipmentForm.storageId = null;
	  
	  this.equipmentForm.runningStatusId = null;
	  this.equipmentForm.sapAcceptStatus = null;
	  this.equipmentForm.sno = null;
	  this.equipmentForm.brandName = null;
	  this.equipmentForm.factoryName = null;
	  
    },
    initSelectData() {
      this.initCategoryList();
      this.initBrandList();
      this.initConditionList();
      this.initStorageList()
    },
    initStorageList() {//获取主分类下拉框
      let postData = {
        page: 0,
        pageSize: 0
      }
      getStorageList(postData).then(res => {
        const {status, data} = res
        if (status != null && data != null && data.data.length > 0) {
          this.storageFullList = [...data.data]
          let tempList = data.data.map(v => {
            return {
              position: v.equipmentStorageName,
              id: v.id,
              costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
              parentId: v.parentId == null || v.parentId === "" ? null : v.parentId,
              children: null,
			  productionAreaFlag: v.productionAreaFlag
            }
          })
          let resultList = arrayToTree(tempList, {
            parentProperty: 'parentId',
            childrenProperty: 'children'
          })
          if (resultList.length > 0) {
            resultList.forEach(item => {
              item.disabled = true;
              let nodeChildren = item.children;
              if (nodeChildren != null && nodeChildren.length > 0) {
                nodeChildren.forEach(secItem => {
                  if (secItem != null && secItem.children != null && secItem.children.length > 0) {
                    secItem.disabled = true;
                    secItem.children.forEach(third => {
                      Object.assign(third, {
                        disabled: false
                      })
                    })
                  }
                })
              }
            })
          }
          console.info(resultList);
          this.treeParams.data = [...resultList]
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
          this.storageNodeList = [...resultList]
        } else {
          this.storageNodeList = []
          this.treeParams.data = []
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
        }
      })
    },
    initCategoryList() {
      let postData = {
        page: 0,
        pageSize: 0,
      }
      getEquipmentCategoryList(postData).then(res => {
        const {status, data} = res;
        if (status === 200 && data != null && data.data != null && data.data.length > 0) {
          this.categoryFullList = [...data.data];
          this.categoryFullNodeList = arrayToTree(this.categoryFullList, {
            parentProperty: 'parentId',
            childrenProperty: 'children',
          })
          let tempList = data.data.filter(v => v.parentId === null || v.parentId === "")
              .map((item) => {
                return {
                  label: item.categoryName,
                  value: item.id,
                }
              })
          if (this.equipmentForm.equipmentMainCategoryId != null && this.equipmentForm.equipmentCategoryId != null) {
            let relatedParent = this.categoryFullNodeList.filter(s => s.id === this.equipmentForm.equipmentMainCategoryId);
            if (relatedParent != null && relatedParent.length > 0) {
              let childrenList = relatedParent[0].children
              if (childrenList != null && childrenList.length > 0) {
                this.equipmentCategoryList = childrenList.map((v) => {
                  return {
                    label: v.categoryName,
                    value: v.id,
                  }
                })
              }
            }
          }
          if (this.equipmentForm.equipmentMainCategoryId != null && this.equipmentForm.equipmentCategoryId != null
              && this.equipmentForm.equipmentSubcategoryId != null) {
            let relatedParent = this.categoryFullNodeList.filter(s => s.id === this.equipmentForm.equipmentMainCategoryId);
            if (relatedParent != null && relatedParent.length === 1 && relatedParent[0].children != null) {
              let middleChildrenList = relatedParent[0].children.filter(s => s.id === this.equipmentForm.equipmentCategoryId);
              if (middleChildrenList != null && middleChildrenList.length == 1 && middleChildrenList[0].children != null) {
                let childrenList = middleChildrenList[0].children.filter(s => s.id === this.equipmentForm.equipmentSubcategoryId);
                if (childrenList != null && childrenList.length > 0) {
                  this.equipmentSubcategoryList = childrenList.map((v) => {
                    return {
                      label: v.categoryName,
                      value: v.id,
                    }
                  })
                }
              }
            }
          }
          this.mainCategoryList = [...tempList]
          if (this.equipmentForm !== null && this.equipmentForm.equipmentMainCategoryId) {
            this.onMainCategoryChange(this.equipmentForm.equipmentMainCategoryId);
          }
          if (this.equipmentForm !== null && this.equipmentForm.equipmentCategoryId) {
            this.onMiddleCategoryChange(this.equipmentForm.equipmentCategoryId);
          }
        } else {
          this.mainCategoryList = []
          // this.equipmentCategoryList = []
        }
      })
    },
    onMainCategoryChange(value) {
      console.info(value)
      if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList != null && this.categoryFullNodeList.length > 0) {
        let targetItem = this.categoryFullList.filter(s => s.id === value);
        let targetNodeList = this.categoryFullNodeList.filter(s => s.id === value);
        if (targetItem && targetNodeList) {
          console.info(targetItem)
          let temp = targetNodeList[0].children === null ? [] : targetNodeList[0].children
          if (temp != null && temp.length > 0) {
            this.equipmentForm.equipmentMainCategoryName = targetItem[0].categoryName;
            this.equipmentCategoryList = temp.map((v) => {
              return {
                label: v.categoryName,
                value: v.id,
              }
            })
          }
        } else {
          this.equipmentCategoryList = []
        }
      } else {
        this.equipmentCategoryList = []
      }
	  
	  this.GetMainProfileList();
    },
    onMiddleCategoryChange(value) {
      console.info(value)
	  // this.equipmentForm.equipmentSubcategoryName = null;
	  // this.equipmentForm.equipmentSubcategoryId = null;
      if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList != null && this.categoryFullNodeList.length > 0) {
        let targetItem = this.categoryFullList.filter(s => s.id === value);
        if (targetItem) {
          let parentId = targetItem[0].parentId;
          if (parentId != null) {
            let mainNodeList = this.categoryFullNodeList.filter(s => s.id === parentId);
            if (mainNodeList != null && mainNodeList.length > 0) {
              console.info(mainNodeList)
              let categoryNodeList = mainNodeList[0].children
              if (categoryNodeList != null && categoryNodeList.length > 0) {
                let middleCategoryNodes = categoryNodeList.filter(s => s.id === value);//中分类
                if (middleCategoryNodes != null && middleCategoryNodes.length === 1) {
                  this.equipmentForm.equipmentCategoryName = middleCategoryNodes[0].categoryName;
                  let subcategoryChildren = middleCategoryNodes[0].children;
                  if (subcategoryChildren != null && subcategoryChildren.length > 0) {
                    this.equipmentSubcategoryList = subcategoryChildren.map((v) => {
                      return {
                        label: v.categoryName,
                        value: v.id,
                      }
                    })
                  }
                }
              }
            }
          }
        } else {
          this.equipmentSubcategoryList = []
        }
      } else {
        this.equipmentSubcategoryList = []
      }
	  
	  
    },
	onMiddleCategoryChange1(value) {
	  console.info(value)
	  this.equipmentForm.equipmentSubcategoryName = null;
	  this.equipmentForm.equipmentSubcategoryId = null;
	  if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList != null && this.categoryFullNodeList.length > 0) {
	    let targetItem = this.categoryFullList.filter(s => s.id === value);
	    if (targetItem) {
	      let parentId = targetItem[0].parentId;
	      if (parentId != null) {
	        let mainNodeList = this.categoryFullNodeList.filter(s => s.id === parentId);
	        if (mainNodeList != null && mainNodeList.length > 0) {
	          console.info(mainNodeList)
	          let categoryNodeList = mainNodeList[0].children
	          if (categoryNodeList != null && categoryNodeList.length > 0) {
	            let middleCategoryNodes = categoryNodeList.filter(s => s.id === value);//中分类
	            if (middleCategoryNodes != null && middleCategoryNodes.length === 1) {
	              this.equipmentForm.equipmentCategoryName = middleCategoryNodes[0].categoryName;
	              let subcategoryChildren = middleCategoryNodes[0].children;
	              if (subcategoryChildren != null && subcategoryChildren.length > 0) {
	                this.equipmentSubcategoryList = subcategoryChildren.map((v) => {
	                  return {
	                    label: v.categoryName,
	                    value: v.id,
	                  }
	                })
	              }
	            }
	          }
	        }
	      }
	    } else {
	      this.equipmentSubcategoryList = []
	    }
	  } else {
	    this.equipmentSubcategoryList = []
	  }
	  
	  this.GetMainProfileList();
	},
    initBrandList() {
      let postData = {
        page: 0,
        pageSize: 0
      }
      getBrandList(postData).then(result => {
        const {status, data} = result;
        if (status === 200 && data.data != null) {
          if (data.data.length > 0) {
            let tempList = data.data.map((item) => {
              return {
                label: item.brandName,
                id: item.id,
                value: item.id,
              }
            })
            this.brandList = [...tempList]
          } else {
            this.brandList = []
          }
        }
      })
    },
    initConditionList() {
		this.conditionList = [];
      this.conditionList.push(
          {
            id: '1',
            label: '使用中',
            value: '1',
            key: 'ok'
          }, {
            id: '2',
            label: '闲置',
            value: '2',
            key: 'free'
          },
          {
            id: '3',
            label: '淘汰',
            value: '3',
            key: 'scrap'
          },
          {
            id: '4',
            label: '报废',
            value: '4',
            key: 'eliminate'
          }
      )
    },
    onEquipmentConditionChange(value) {
      let targetItem = this.conditionList.filter(s => s.value === value);
      if (targetItem != null && targetItem.length > 0) {
        let temp = targetItem[0];
        this.equipmentForm.conditionDesc = temp.label;
      }
    },
	onStorageNodeClick(e) {
		console.info(e)
		const {
			productionAreaFlag
		} = e;
		if (productionAreaFlag === 1) {
			this.equipmentForm.conditionId = "1";
		} else {
			this.equipmentForm.conditionId = null;
		}
	}
  }
}
</script>
<style lang="scss">

.equipment-edit-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .equipmentFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .equipment-main-form {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
	
	.lastform{
		display: flex;
		.el-form-item__content{
			display: flex;
			// flex-direction: column;
		}
		
	}
	
	.el-upload {
		display: flex;
	}
	
	.el-upload-list__item{
		width: 70px;
		height: 70px;
	}
	.el-upload--picture-card{
		width: 70px;
		height: 70px;
		align-items: center;
	}
	.el-icon-plus{
		margin: 0 auto;
	}
	
	.upload-demo1>.el-upload--picture-card{
		display: none;
	}
  }
}
</style>
