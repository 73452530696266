<template>
	<div class="equipment-edit-container">
		<div class="operate">
			<dsn-button size="medium" icon="el-icon-back" type="primary" @click.native="handleBack">
				{{ this.$t('commbtn.back') }}
			</dsn-button>
			<!--      <dsn-button-save-->
			<!--          @click.native="checkAdd('materialGroupForm')"-->
			<!--          v-if="hasUpdateBtnAuth"-->
			<!--      >保存-->
			<dsn-button-save @click.native="checkAdd(['equipmentFormRef'])" size="medium">{{ this.$t('commbtn.save') }}
			</dsn-button-save>
			<dsn-button size="medium" type plain icon="el-icon-refresh" class="reset-button"
				@click.native="handleReset(['equipmentFormRef'])">{{ this.$t('commbtn.reset') }}
			</dsn-button>
		</div>
		<div class="info">
			<el-form :model="equipmentForm" ref="equipmentFormRef" :inline="true" :rules="equipmentFormRules"
				class="equipment-main-form">
				<el-row>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.mainCategory')"
							prop="equipment_main_category_id" :label-width="labelWidth">
							<dsn-select v-model.trim="equipmentForm.equipment_main_category_id" filterable
								:placeholder="this.$t('commmsg.pleaseSelect')+this.$t('equipments.fields.mainCategory')"
								size="medium" @change="onMainCategoryChange">
								<el-option v-for="item in mainCategoryList" :key="item.key" :label="item.label"
									:value="item.value"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.middleCategory')" prop="equipment_category_id"
							:label-width="labelWidth">
							<dsn-select v-model.trim="equipmentForm.equipment_category_id" filterable
								:placeholder="this.$t('commmsg.pleaseSelect')+this.$t('equipments.fields.middleCategory')"
								size="medium" @change="onMiddleCategoryChange">
								<el-option v-for="item in equipmentCategoryList" :key="item.key" :label="item.label"
									:value="item.value"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.subcategory')" prop="equipment_subcategory_id"
							:label-width="labelWidth">
							<dsn-select v-model.trim="equipmentForm.equipment_subcategory_id" filterable
								:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.subcategory')"
								size="medium" @change="onSubcategorySelect">
								<el-option v-for="item in equipmentSubcategoryList" :key="item.key" :label="item.label"
									:value="item.value"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item :label="this.$t('commmsg.categoryname')" prop="name" :label-width="labelWidth">
							<dsn-input v-model.trim="equipmentForm.name"
								:placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('commmsg.categoryname')">
							</dsn-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.brand')" prop="brand_name"
							:label-width="labelWidth">
							<dsn-input v-model.trim="equipmentForm.brand_name"
								:placeholder="this.$t('commmsg.pleaseInput') +' '+this.$t('equipments.fields.brand')">
							</dsn-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
					  <el-form-item :label="this.$t('equipments.fields.modelType')" prop="model_type" :label-width="labelWidth">
					    <dsn-input
					        v-model.trim="equipmentForm.model_type"
					        :placeholder="this.$t('commmsg.pleaseInput') +' '+this.$t('equipments.fields.modelType')"
					    ></dsn-input>
					  </el-form-item>
					</el-col>
				</el-row>

			</el-form>

		</div>
	</div>

</template>
<script>
	import {
		updateEquipments,editMainProfileManager
	} from "@/api/efms/equipments/equipments.api";
	import {
		getBrandList
	} from "@/api/efms/equipments/equipment.brand.api";
	import {
		getEquipmentCategoryList
	} from "@/api/efms/equipments/equipment.category.api";
	import arrayToTree from "array-to-tree";
	import {
		mapGetters
	} from "vuex";
	import _ from "lodash";
	import {
		getStorageList
	} from "@/api/efms/equipments/equipment.storage.api";
	import StorageTreeSelect
	from "@/views/mes/basic_settings/equipments_position_ref_settings/components/storage-tree-select";

	export default {
		name: 'MainEquipmentEdit',
		components: {
			StorageTreeSelect
		},
		data() {
			return {
				isEditStation: false, //是否可编辑站位



				equipmentForm: {
					equipment_main_category_id: null,
					equipment_main_category_name: null,
					equipment_category_id: null,
					equipment_category_name: null,
					equipment_subcategory_id: null,
					equipment_subcategory_name: null,

					name: null,
					brand_name: null,
					model_type:null,

				},
				selectParams: {
					multiple: false,
					clearable: true,
					placeholder: '请选择'
				},
				treeParams: {
					clickParent: true,
					filterable: false,
					'check-strictly': true,
					'default-expand-all': true,
					'expand-on-click-node': false,
					data: [],
					props: {
						children: 'children',
						label: 'position',
						disabled: 'disabled',
						value: 'id'
					}
				},
				labelWidth: "120px",
				categoryFullList: [],
				categoryFullNodeList: [],
				equipmentCategoryList: [],
				equipmentSubcategoryList: [],
				conditionList: [],
				mainDeptList: [],
				brandList: [],
				storageNodeList: [],
				storageFullList: [],
				mainCategoryList: [],
				runningSateList: [{
						label: '开机',
						value: "1",
					},
					{
						label: '关机',
						value: "0",
					}, {
						label: '其他',
						value: "-1",
					}
				],
				factoryList: [{
					label: '西丽工厂',
					value: '西丽工厂'
				}]
			}
		},
		created() {
			this.operateType = this.$route.query.operateType;
			this.cloneList = _.cloneDeep(JSON.parse(JSON.stringify(this.equipmentList)));
			this.equipmentForm =
				this.cloneList[0] ||
				JSON.parse(localStorage.getItem("equipmentList"))[0];
			console.log('this.equipmentForm ----------------------', this.equipmentForm);
			this.initSelectData();
		},
		activated() {
			this.initSelectData();
		},
		computed: {
			...mapGetters(["equipmentList"]),
			equipmentFormRules: function() {
				let that = this
				return {
					equipment_main_category_id: [{
						required: true,
						message: `${that.$t('commmsg.pleaseInput')}${this.$t('equipments.fields.mainCategory')}`,
						trigger: "change"
					}],
					equipment_category_id: [{
						required: true,
						message: `${that.$t('commmsg.pleaseInput')}${this.$t('equipments.fields.middleCategory')}`,
						trigger: "change"
					}],
					equipment_subcategory_id: [{
						required: true,
						message: `${that.$t('commmsg.pleaseInput')}${this.$t('equipments.fields.subcategory')}`,
						trigger: "change"
					}],
					brand_name: [{
						required: false,
						message: "请选择品牌",
						trigger: "change"
					}],
					name: [{
						required: true,
						message: "请输入设备名称",
						trigger: "change"
					}],
					sno: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.sno')}`,
						trigger: "change"
					}],
					modelType: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.modelType')}`,
						trigger: "change"
					}],
					deptId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.dept')}`,
						trigger: "change"
					}],
					userId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.user')}`,
						trigger: "change"
					}],
					directorDeptId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.directorDept')}`,
						trigger: "change"
					}],
					directorId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.director')}`,
						trigger: "change"
					}],
					conditionId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.condition')}`,
						trigger: "change"
					}],
				}
			}
		},
		methods: {
			handleBack() {
				this.$router.pushAndClear({
					name: "mainGear"
				}, {
					deleteItem: this.$route,
					current: this.$route,
				});
			},
			checkAdd(formName) {
				let count = 0;
				formName.forEach((element) => {
					this.$refs[element].validate((valid) => {
						if (valid) {
							count++;
						} else {
							return false;
						}
					});
				});
				if (count >= 1) {
					console.info(count)
					this.messageBox({
							type: "success",
							title: "保存",
							content: "是否保存数据?",
						})
						.then(() => {
							this.handleDataSave();
						})
						.catch(() => {
							this.notification().warn({
								body: "已取消保存",
							});
						});
				}
			},
			handleDataSave() {
				const postData = {};
				// console.info("handleDataSave")
				postData['equipment_main_category_id'] = this.equipmentForm.equipment_main_category_id;
				postData['equipment_main_category_name'] = this.equipmentForm.equipment_main_category_name;
				postData['equipment_category_id'] = this.equipmentForm.equipment_category_id;
				postData['equipment_category_name'] = this.equipmentForm.equipment_category_name;
				postData['equipment_subcategory_name'] = this.equipmentForm.equipment_subcategory_name
				postData['equipment_subcategory_id'] = this.equipmentForm.equipment_subcategory_id


				postData['name'] = this.equipmentForm.name
				postData['brand_name'] = this.equipmentForm.brand_name
				postData['model_type'] = this.equipmentForm.modeltype

				postData['id'] = this.equipmentForm.id
				postData['brand_id'] = this.equipmentForm.brand_id
				postData['model_type'] = this.equipmentForm.model_type
				postData['del_flag'] = this.equipmentForm.del_flag
				postData['detailList'] = this.equipmentForm.detailList
				postData['amount'] = this.equipmentForm.amount
				postData['zeroing_month'] = this.equipmentForm.zeroing_month


				// console.log('this.equipmentForm ----------------------', this.equipmentForm);


				// postData['storageName'] = this.equipmentForm.storageName;
				console.info("postData", postData)
				editMainProfileManager(postData).then(result => {
				  const {status, data, errMsg} = result;
				  if (status === 200) {
				    this.notification().success({
				      body: `${this.$t('commmsg.addSuccess')}`,
				    });
				    this.handleBack();
				  } else {
				    this.notification().error({
				      body: errMsg,
				    });
				  }

				})
			},
			onSubcategorySelect(value) {
				if (this.equipmentSubcategoryList != null && this.equipmentSubcategoryList.length > 0) {
					let targetItem = this.equipmentSubcategoryList.filter(s => s.value === value);
					if (targetItem != null && targetItem.length > 0) {
						this.equipmentForm.equipment_subcategory_name = targetItem[0].label
					} else {
						this.equipmentForm.equipment_subcategory_name = null;
						this.equipmentForm.equipment_subcategory_id = null;
					}
				}
			},
			handleReset(formName) {
				if (this.operateType === "add") {
					formName.forEach((element) => {
						this.$refs[element].resetFields();
					});
				}
			},
			initSelectData() {
				this.initCategoryList();
				this.initBrandList();
				this.initConditionList();
				this.initStorageList()
			},
			initStorageList() { //获取主分类下拉框
				let postData = {
					page: 0,
					pageSize: 0
				}
				getStorageList(postData).then(res => {
					const {
						status,
						data
					} = res
					if (status != null && data != null && data.data.length > 0) {
						this.storageFullList = [...data.data]
						let tempList = data.data.map(v => {
							return {
								position: v.equipmentStorageName,
								id: v.id,
								costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
								parentId: v.parentId == null || v.parentId === "" ? null : v.parentId,
								children: null,
							}
						})
						let resultList = arrayToTree(tempList, {
							parentProperty: 'parentId',
							childrenProperty: 'children'
						})
						if (resultList.length > 0) {
							resultList.forEach(item => {
								item.disabled = true;
								let nodeChildren = item.children;
								if (nodeChildren != null && nodeChildren.length > 0) {
									nodeChildren.forEach(secItem => {
										if (secItem != null && secItem.children != null && secItem
											.children.length > 0) {
											secItem.disabled = true;
											secItem.children.forEach(third => {
												Object.assign(third, {
													disabled: false
												})
											})
										}
									})
								}
							})
						}
						console.info(resultList);
						this.treeParams.data = [...resultList]
						// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
						this.storageNodeList = [...resultList]
					} else {
						this.storageNodeList = []
						this.treeParams.data = []
						// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
					}
				})
			},
			initCategoryList() {
				let postData = {
					page: 0,
					pageSize: 0,
				}
				getEquipmentCategoryList(postData).then(res => {
					const {
						status,
						data
					} = res;
					if (status === 200 && data != null && data.data != null && data.data.length > 0) {
						this.categoryFullList = [...data.data];
						this.categoryFullNodeList = arrayToTree(this.categoryFullList, {
							parentProperty: 'parentId',
							childrenProperty: 'children',
						})
						let tempList = data.data.filter(v => v.parentId === null || v.parentId === "")
							.map((item) => {
								return {
									label: item.categoryName,
									value: item.id,
								}
							})
						if (this.equipmentForm.equipment_main_category_id != null && this.equipmentForm
							.equipment_category_id != null) {
							let relatedParent = this.categoryFullNodeList.filter(s => s.id === this.equipmentForm
								.equipment_main_category_id);
							if (relatedParent != null && relatedParent.length > 0) {
								let childrenList = relatedParent[0].children
								if (childrenList != null && childrenList.length > 0) {
									this.equipmentCategoryList = childrenList.map((v) => {
										return {
											label: v.categoryName,
											value: v.id,
										}
									})
								}
							}
						}
						if (this.equipmentForm.equipment_main_category_id != null && this.equipmentForm
							.equipment_category_id != null &&
							this.equipmentForm.equipment_subcategory_id != null) {
							let relatedParent = this.categoryFullNodeList.filter(s => s.id === this.equipmentForm
								.equipment_main_category_id);
							if (relatedParent != null && relatedParent.length === 1 && relatedParent[0].children !=
								null) {
								let middleChildrenList = relatedParent[0].children.filter(s => s.id === this
									.equipmentForm.equipment_category_id);
								if (middleChildrenList != null && middleChildrenList.length == 1 &&
									middleChildrenList[0].children != null) {
									let childrenList = middleChildrenList[0].children.filter(s => s.id === this
										.equipmentForm.equipment_subcategory_id);
									if (childrenList != null && childrenList.length > 0) {
										this.equipmentSubcategoryList = childrenList.map((v) => {
											return {
												label: v.categoryName,
												value: v.id,
											}
										})
									}
								}
							}
						}
						this.mainCategoryList = [...tempList]
						if (this.equipmentForm !== null && this.equipmentForm.equipment_main_category_id) {
							this.onMainCategoryChange(this.equipmentForm.equipment_main_category_id);
						}
						// if (this.equipmentForm !== null && this.equipmentForm.equipment_category_id) {
						//   this.onMiddleCategoryChange(this.equipmentForm.equipment_category_id);
						// }
					} else {
						this.mainCategoryList = []
						// this.equipmentCategoryList = []
					}
				})
			},
			onMainCategoryChange(value) {
				console.info(value)
				if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList !=
					null && this.categoryFullNodeList.length > 0) {
					let targetItem = this.categoryFullList.filter(s => s.id === value);
					let targetNodeList = this.categoryFullNodeList.filter(s => s.id === value);
					if (targetItem && targetNodeList) {
						console.info(targetItem)
						let temp = targetNodeList[0].children === null ? [] : targetNodeList[0].children
						if (temp != null && temp.length > 0) {
							this.equipmentForm.equipment_main_category_name = targetItem[0].categoryName;
							this.equipmentCategoryList = temp.map((v) => {
								return {
									label: v.categoryName,
									value: v.id,
								}
							})
						}
					} else {
						this.equipmentCategoryList = []
					}
				} else {
					this.equipmentCategoryList = []
				}
			},
			onMiddleCategoryChange(value) {
				console.info(value)
				if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList !=
					null && this.categoryFullNodeList.length > 0) {
					let targetItem = this.categoryFullList.filter(s => s.id === value);
					if (targetItem) {
						let parentId = targetItem[0].parentId;
						if (parentId != null) {
							let mainNodeList = this.categoryFullNodeList.filter(s => s.id === parentId);
							if (mainNodeList != null && mainNodeList.length > 0) {
								console.info(mainNodeList)
								let categoryNodeList = mainNodeList[0].children
								if (categoryNodeList != null && categoryNodeList.length > 0) {
									let middleCategoryNodes = categoryNodeList.filter(s => s.id === value); //中分类
									if (middleCategoryNodes != null && middleCategoryNodes.length === 1) {
										this.equipmentForm.equipment_category_name = middleCategoryNodes[0].categoryName;
										this.equipmentForm.equipment_subcategory_name = '';
										this.equipmentForm.equipment_subcategory_id = '';
										let subcategoryChildren = middleCategoryNodes[0].children;
										if (subcategoryChildren != null && subcategoryChildren.length > 0) {
											this.equipmentSubcategoryList = subcategoryChildren.map((v) => {
												return {
													label: v.categoryName,
													value: v.id,
												}
											})
										}
									}
								}
							}
						}
					} else {
						this.equipmentSubcategoryList = []
					}
				} else {
					this.equipmentSubcategoryList = []
				}
			},
			initBrandList() {
				let postData = {
					page: 0,
					pageSize: 0
				}
				getBrandList(postData).then(result => {
					const {
						status,
						data
					} = result;
					if (status === 200 && data.data != null) {
						if (data.data.length > 0) {
							let tempList = data.data.map((item) => {
								return {
									label: item.brandName,
									id: item.id,
									value: item.id,
								}
							})
							this.brandList = [...tempList]
						} else {
							this.brandList = []
						}
					}
				})
			},
			initConditionList() {
				this.conditionList = [];
				this.conditionList.push({
					id: '1',
					label: '使用中',
					value: '1',
					key: 'ok'
				}, {
					id: '2',
					label: '闲置',
					value: '2',
					key: 'free'
				}, {
					id: '3',
					label: '淘汰',
					value: '3',
					key: 'scrap'
				}, {
					id: '4',
					label: '报废',
					value: '4',
					key: 'eliminate'
				})
			},
			onEquipmentConditionChange(value) {
				let targetItem = this.conditionList.filter(s => s.value === value);
				if (targetItem != null && targetItem.length > 0) {
					let temp = targetItem[0];
					this.equipmentForm.conditionDesc = temp.label;
				}
			}
		}
	}
</script>
<style lang="scss">
	.equipment-edit-container {
		.operate {
			padding: 16px;

			.el-button {
				margin: 0 8px 0 0;
			}
		}

		.info {
			background-color: #ffffff;
			padding: 16px;

			.equipmentFormTop {
				.el-form-item {
					margin: 0 24px 16px 0;
				}
			}

			.equipment-main-form {
				.el-form-item {
					margin: 0 24px 16px 0;

					.el-select {
						width: 320px;
					}

					.el-input {
						width: 320px;
					}
				}
			}
		}
	}
</style>
