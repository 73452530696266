<template>
  <div class="equipment-add-container">
    <div class="operate">
      <dsn-button
          size="medium"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save size="medium"
                       @click.native="checkAdd(['equipmentFormRef'])">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="medium"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['equipmentFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="equipmentForm"
          ref="equipmentFormRef"
          :inline="true"
          :rules="userFormRules"
          class="equipment-main-form"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipments.fields.mainCategory')" prop="equipmentMainCategoryId"
                          :label-width="labelWidth">
              <dsn-select
                  v-model.trim="equipmentForm.equipmentMainCategoryId"
                  filterable
                  :placeholder="this.$t('commmsg.pleaseSelect')+this.$t('equipments.fields.mainCategory')"
                  size="medium"
                  @change="onMainCategoryChange"
              >
                <el-option
                    v-for="item in mainCategoryList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </dsn-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipments.fields.middleCategory')" prop="equipmentCategoryId"
                          :label-width="labelWidth">
              <dsn-select
                  v-model.trim="equipmentForm.equipmentCategoryId"
                  filterable
                  :placeholder="this.$t('commmsg.pleaseSelect')+this.$t('equipments.fields.middleCategory')"
                  size="medium"
                  @change="onMiddleCategoryChange"
              >
                <el-option
                    v-for="item in equipmentCategoryList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </dsn-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipments.fields.subcategory')" prop="equipmentSubcategoryId"
                          :label-width="labelWidth">
              <dsn-select
                  v-model.trim="equipmentForm.equipmentSubcategoryId"
                  filterable
                  :placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.subcategory')"
                  size="medium"
                  @change="onSubcategorySelect"
              >
                <el-option
                    v-for="item in equipmentSubcategoryList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </dsn-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="this.$t('commmsg.categoryname')" prop="name" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="equipmentForm.name"
                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('commmsg.categoryname')"
              ></dsn-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="this.$t('equipments.fields.brand')" prop="brandName" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="equipmentForm.brandName"
                  :placeholder="this.$t('commmsg.pleaseInput') +' '+this.$t('equipments.fields.brand')"
              ></dsn-input>
            </el-form-item>
          </el-col>
		  <el-col :span="8">
		    <el-form-item :label="this.$t('equipments.fields.modelType')" prop="modeltype" :label-width="labelWidth">
		      <dsn-input
		          v-model.trim="equipmentForm.modeltype"
		          :placeholder="this.$t('commmsg.pleaseInput') +' '+this.$t('equipments.fields.modelType')"
		      ></dsn-input>
		    </el-form-item>
		  </el-col>
		  
          
        </el-row>

      </el-form>
    </div>
  </div>

</template>
<script>
import {addEquipments,MainProfileManager} from "@/api/efms/equipments/equipments.api";
import {getBrandList} from "@/api/efms/equipments/equipment.brand.api";
import {getEquipmentCategoryList} from "@/api/efms/equipments/equipment.category.api";
import arrayToTree from "array-to-tree";
import {getStorageList} from "@/api/efms/equipments/equipment.storage.api";
import StorageTreeSelect
  from "@/views/mes/basic_settings/equipments_position_ref_settings/components/storage-tree-select";

export default {
  name: 'MainEquipmentAdd',
  components: {StorageTreeSelect},
  data() {
    const valiIP = (rule, value, callback) => {
      if (value === "") {
        callback("IP地址不能为空");
      }
      let reg = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
      if (!reg.test(value)) {
        callback("IP地址格式错误");
      }
      callback();
    }
    return {
      isEditStation: false, //是否可编辑站位
      equipmentForm: {
        equipmentMainCategoryId: null,
        equipmentMainCategoryName: null,
        equipmentCategoryId: null,
        equipmentCategoryName: null,
        equipmentSubcategoryId: null,
        equipmentSubcategoryName: null,
		
        name: null,
		brandName: null,
		
		modeltype:null,
        
      },
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: `${this.$t('commmsg.pleaseSelect') + ' ' + this.$t('equipments.fields.storage')}`

      },
      treeParams: {
        clickParent: true,
        filterable: false,
        'check-strictly': true,
        'default-expand-all': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          children: 'children',
          label: 'position',
          disabled: 'disabled',
          value: 'id'
        }
      },
      labelWidth: "130px",
      categoryFullList: [],
      categoryFullNodeList: [],
      equipmentCategoryList: [],
      equipmentSubcategoryList: [],
      conditionList: [],
      mainDeptList: [],
      brandList: [],
      storageNodeList: [],
      storageFullList: [],
      mainCategoryList: [],
      runningSateList: [
        {
          label: '开机',
          value: 1,
        },
        {
          label: '关机',
          value: 0,
        }, {
          label: '其他',
          value: 2,
        }
      ],
      factoryList: [
        {
          label: '西丽工厂',
          value: '西丽工厂'
        }
      ]
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.initSelectData();
	
	
	this.initDate();
  },
  computed: {
    userFormRules: function () {
      let that = this
      return {
		  
		  equipmentMainCategoryId: [{required: true, message: `${that.$t('commmsg.pleaseInput')}${this.$t('equipments.fields.mainCategory')}`, trigger: "change"}],
        equipmentCategoryId: [{required: true, message: `${that.$t('commmsg.pleaseInput')}${this.$t('equipments.fields.middleCategory')}`, trigger: "change"}],
        equipmentSubcategoryId: [{required: true, message: `${that.$t('commmsg.pleaseInput')}${this.$t('equipments.fields.subcategory')}`, trigger: "change"}],
        brandName: [{required: true, message: "请选择品牌", trigger: "change"}],
        name: [{required: true, message: "请输入设备名称", trigger: "change"}],
        sno: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.sno')}`,
          trigger: "change"
        }],
        modelType: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.modelType')}`,
          trigger: "change"
        }],
        deptId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.dept')}`,
          trigger: "change"
        }],
        userId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.user')}`,
          trigger: "change"
        }],
        directorDeptId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.directorDept')}`,
          trigger: "change"
        }],
        directorId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.director')}`,
          trigger: "change"
        }],
        conditionId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.condition')}`,
          trigger: "change"
        }],
      }
    }
  },
  methods: {
	  
	  getDate() {
		  var year = new Date().getFullYear();
		  var month = (new Date().getMonth() + 1) >= 10 ? (new Date().getMonth() + 1) : '0'+(new Date().getMonth() + 1);
		  var day = new Date().getDate() >= 10 ? new Date().getDate() : '0'+new Date().getDate();
	      return year +"-" +month+"-" +day
	      ;
	    },
	    initDate() {
	      this.equipmentForm.acquisitionDate = this.getDate()
		  console.log(this.equipmentForm.acquisitionDate);
	    },
	  
    handleBack() {
      this.$router.pushAndClear(
          {name: "mainGear"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      const postData = {};
      postData['equipment_main_category_id'] = this.equipmentForm.equipmentMainCategoryId;
      postData['equipment_main_category_name'] = this.equipmentForm.equipmentMainCategoryName;
      postData['equipment_category_id'] = this.equipmentForm.equipmentCategoryId;
      postData['equipment_category_name'] = this.equipmentForm.equipmentCategoryName;
      postData['equipment_subcategory_name'] = this.equipmentForm.equipmentSubcategoryName
      postData['equipment_subcategory_id'] = this.equipmentForm.equipmentSubcategoryId
		
		
      postData['name'] = this.equipmentForm.name
      postData['brand_name'] = this.equipmentForm.brandName
	  postData['model_type'] = this.equipmentForm.modeltype
	  
	  
	  postData['id'] = 0
	  postData['brand_id'] = ''
	  postData['model_type'] = ''
	  postData['del_flag'] = 0
	  postData['detailList'] = []
	  

      // postData['storageName'] = this.equipmentForm.storageName;
	  console.info("postData",postData)
      MainProfileManager(postData).then(result => {
        const {status, data} = result;
        if (status === 200 && data.resultCode === 0) {
          this.notification().success({
            body: `${this.$t('commmsg.addSuccess')}`,
          });
          this.handleBack();
          return;
        }
        this.notification().error({
          body: data.errMsg,
        });
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    },
    initSelectData() {
      this.equipmentForm.factoryName = this.factoryList[0].value;
      this.initCategoryList();
      this.initBrandList();
      this.initConditionList();
      this.initStorageList();
    },
    initCategoryList() {
      let postData = {
        page: 0,
        pageSize: 0,
      }
      getEquipmentCategoryList(postData).then(res => {
        const {status, data} = res;
        if (status === 200 && data != null && data.data != null && data.data.length > 0) {
          this.categoryFullList = [...data.data];
		  console.info('this.categoryFullList -------------------------',this.categoryFullList);
          this.categoryFullNodeList = arrayToTree(this.categoryFullList, {
            parentProperty: 'parentId',
            childrenProperty: 'children',
          })
          console.info('this.categoryFullNodeList ++++++++++++++++++++',this.categoryFullNodeList);
          let tempList = data.data.filter(v => v.parentId === null || v.parentId === "")
              .map((item) => {
                return {
                  label: item.categoryName,
                  value: item.id,
                }
              })
          this.mainCategoryList = [...tempList];
          if (this.mainCategoryList.length > 0) {
            // this.equipmentForm.equipmentMainCategoryId=this.mainCategoryList
            let targets = this.mainCategoryList.filter(s => s["label"] === '生产设备')
            if (targets != null && targets.length > 0) {
              console.info('target')
              // this.equipmentForm.equipmentMainCategoryId = targets[0].value;
              // this.onMainCategoryChange(this.equipmentForm.equipmentMainCategoryId)
            } else {
              this.equipmentForm.equipmentMainCategoryId = null;
            }
            console.info(this.mainCategoryList)
          }
          // this.equipmentCategoryList = [...tempList]
        } else {
          this.mainCategoryList = [];
          // this.equipmentCategoryList = []
        }
      })
    },
    onMainCategoryChange(value) {
		
      if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList != null && this.categoryFullNodeList.length > 0) {
        let targetItem = this.categoryFullList.filter(s => s.id === value);
        let targetNodeList = this.categoryFullNodeList.filter(s => s.id === value);
        if (targetItem && targetNodeList) {
          let temp = targetNodeList[0].children === null ? [] : targetNodeList[0].children
          if (temp != null && temp.length > 0) {
            this.equipmentForm.equipmentMainCategoryName = targetItem[0].categoryName;
            this.equipmentCategoryList = temp.map((v) => {
              return {
                label: v.categoryName,
                value: v.id,
              }
            })
          }
        } else {
          this.equipmentCategoryList = []
        }
      } else {
        this.equipmentCategoryList = []
      }
    },
    onMiddleCategoryChange(value) {
      if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList != null && this.categoryFullNodeList.length > 0) {
        let targetItem = this.categoryFullList.filter(s => s.id === value);
        if (targetItem) {
          let parentId = targetItem[0].parentId;
          if (parentId != null) {
            let mainNodeList = this.categoryFullNodeList.filter(s => s.id === parentId);
            if (mainNodeList != null && mainNodeList.length > 0) {
              console.info(mainNodeList)
              let categoryNodeList = mainNodeList[0].children
              if (categoryNodeList != null && categoryNodeList.length > 0) {
                let middleCategoryNodes = categoryNodeList.filter(s => s.id === value);//中分类
				
                if (middleCategoryNodes != null && middleCategoryNodes.length === 1) {
					// console.log('middleCategoryNodes[0].categoryName --------------',middleCategoryNodes[0].categoryName);
                  this.equipmentForm.equipmentCategoryName = middleCategoryNodes[0].categoryName;
				  // this.$forceUpdate();
                  let subcategoryChildren = middleCategoryNodes[0].children;
                  if (subcategoryChildren != null && subcategoryChildren.length > 0) {
                    this.equipmentSubcategoryList = subcategoryChildren.map((v) => {
                      return {
                        label: v.categoryName,
                        value: v.id,
                      }
                    })
                  }
                }
              }
            }
          }
        } else {
          this.equipmentSubcategoryList = []
        }
      } else {
        this.equipmentSubcategoryList = []
      }
    },
    onSubcategorySelect(value) {
      if (this.equipmentSubcategoryList != null && this.equipmentSubcategoryList.length > 0) {
        let targetItem = this.equipmentSubcategoryList.filter(s => s.value === value);
        if (targetItem != null && targetItem.length > 0) {
          this.equipmentForm.equipmentSubcategoryName = targetItem[0].label
        } else {
          this.equipmentForm.equipmentSubcategoryName = null;
          this.equipmentForm.equipmentSubcategoryId = null;
        }
      }
    },
    initSubcategory(categoryId) {

    },
    initBrandList() {
      let postData = {
        page: 0,
        pageSize: 0
      }
      getBrandList(postData).then(result => {
        const {status, data} = result;
        if (status === 200 && data.data != null) {
          if (data.data.length > 0) {
            let tempList = data.data.map((item) => {
              return {
                label: item.brandName,
                id: item.id,
                value: item.id,
              }
            })
            this.brandList = [...tempList]
          } else {
            this.brandList = []
          }
        }
      })
    },
    initStorageList() {//获取主分类下拉框
      let postData = {
        page: 0,
        pageSize: 0
      }
      getStorageList(postData).then(res => {
        const {status, data} = res
        if (status != null && data != null && data.data.length > 0) {
          this.storageFullList = [...data.data]
          let tempList = data.data.map(v => {
            return {
              position: v.equipmentStorageName,
              id: v.id,
              costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
              parentId: v.parentId == null || v.parentId === "" ? null : v.parentId,
              children: null,
              productionAreaFlag: v.productionAreaFlag
            }
          })
          let resultList = arrayToTree(tempList, {
            parentProperty: 'parentId',
            childrenProperty: 'children'
          })
          if (resultList.length > 0) {
            resultList.forEach(item => {
              item.disabled = true;
              let nodeChildren = item.children;
              if (nodeChildren != null && nodeChildren.length > 0) {
                nodeChildren.forEach(secItem => {
                  if (secItem != null && secItem.children != null && secItem.children.length > 0) {
                    secItem.disabled = true;
                    secItem.children.forEach(third => {
                      Object.assign(third, {
                        disabled: false
                      })
                    })
                  }
                })
              }
            })
          }
          this.treeParams.data = [...resultList]
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
          this.storageNodeList = [...resultList]
        } else {
          this.storageNodeList = []
          this.treeParams.data = []
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
        }
      })
    },
    initConditionList() {
      this.conditionList.push(
          {
            id: '1',
            label: '使用中',
            value: '1',
            key: 'ok'
          }, {
            id: '2',
            label: '闲置',
            value: '2',
            key: 'free'
          },
          {
            id: '3',
            label: '淘汰',
            value: '3',
            key: 'scrap'
          },
          {
            id: '4',
            label: '报废',
            value: '4',
            key: 'eliminate'
          }
      )
    },
    onBrandChange(value) {
      console.info(value)
      if (this.brandList != null && this.brandList.length > 0) {
        let targetItem = this.brandList.filter(s => s.value === value)
        if (targetItem != null && targetItem.length === 1) {
          this.equipmentForm.brandName = targetItem[0].label;
        } else {
          this.equipmentForm.brandId = null;
          this.equipmentForm.brandName = null;
        }
      }
    },
    onStorageNodeClick(e) {
      console.info(e)
      const {productionAreaFlag} = e;
      if (productionAreaFlag === 1) {
        this.equipmentForm.conditionId = "1";
      } else {
        this.equipmentForm.conditionId = null;
      }
    }
  }
}
</script>
<style lang="scss">

.equipment-add-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .equipmentFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .equipment-main-form {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }
}
</style>
