import request from "@/service/";


export const addBrand = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/refs/brands`,
        data
    );
}

export const updateBrand = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/refs/brands`,
        data
    );
}


export const getBrandList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/brands`,
        {
            params: params
        }
    );
}

export const getBrandById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/brands/${id}`,
    );
}

export const deleteOneBrand = (id) => {
    return request.delete(
        `${window.VUE_APP_URL}/api/refs/brands/${id}`,
    );
}
