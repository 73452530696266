<template>
	<div class="equipment-add-container">
		<div class="operate">
			<dsn-button size="medium" icon="el-icon-back" type="primary"
				@click.native="handleBack">{{ this.$t('commbtn.back') }}
			</dsn-button>
			<!--      <dsn-button-save-->
			<!--          @click.native="checkAdd('materialGroupForm')"-->
			<!--          v-if="hasUpdateBtnAuth"-->
			<!--      >保存-->
			<dsn-button-save size="medium" @click.native="checkAdd(['equipmentFormRef'])">{{ this.$t('commbtn.save') }}
			</dsn-button-save>
			<dsn-button size="medium" type plain icon="el-icon-refresh" class="reset-button"
				@click.native="handleReset(['equipmentFormRef'])">{{ this.$t('commbtn.reset') }}
			</dsn-button>
		</div>
		<div class="info">
			<el-form :model="equipmentForm" ref="equipmentFormRef" :inline="true" :rules="userFormRules"
				class="equipment-main-form">
				<el-row>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.mainCategory')" prop="equipmentMainCategoryId"
							:label-width="labelWidth">
							<dsn-select v-model.trim="equipmentForm.equipmentMainCategoryId" filterable
								:placeholder="this.$t('commmsg.pleaseSelect')+this.$t('equipments.fields.mainCategory')"
								size="medium" @change="onMainCategoryChange">
								<el-option v-for="item in mainCategoryList" :key="item.key" :label="item.label"
									:value="item.value"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.middleCategory')" prop="equipmentCategoryId"
							:label-width="labelWidth">
							<dsn-select v-model.trim="equipmentForm.equipmentCategoryId" filterable
								:placeholder="this.$t('commmsg.pleaseSelect')+this.$t('equipments.fields.middleCategory')"
								size="medium" @change="onMiddleCategoryChange">
								<el-option v-for="item in equipmentCategoryList" :key="item.key" :label="item.label"
									:value="item.value"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.subcategory')" prop="equipmentSubcategoryId"
							:label-width="labelWidth">
							<dsn-select v-model.trim="equipmentForm.equipmentSubcategoryId" filterable
								:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.subcategory')"
								size="medium" @change="onSubcategorySelect">
								<el-option v-for="item in equipmentSubcategoryList" :key="item.key" :label="item.label"
									:value="item.value"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>
				</el-row>









				<el-row>
					<el-col :span="8">
						<el-form-item :label="this.$t('commbtn.maingear')" :label-width="labelWidth">
							<dsn-select v-model.trim="maingear" filterable
								:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('commbtn.maingear')"
								size="medium" @change="onmaingearSelect">
								<el-option v-for="item in MainProfileList" :key="item.id"
									:label="item.name + ' | ' + item.brand_name + ' | ' + item.model_type"
									:value="item.id"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('commbtn.addgear')" :label-width="labelWidth">
							<el-checkbox v-model="isNewGear"></el-checkbox>
						</el-form-item>

					</el-col>

				</el-row>










				<el-row>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.name')" prop="name" :label-width="labelWidth">
							<dsn-input v-model.trim="equipmentForm.name"
								:placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('equipments.fields.name')"
								:disabled="isNewGear==false"></dsn-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.brand')" prop="brandName"
							:label-width="labelWidth">
							<dsn-input v-model.trim="equipmentForm.brandName"
								:placeholder="this.$t('commmsg.pleaseInput') +' '+this.$t('equipments.fields.brand')"
								:disabled="isNewGear==false"></dsn-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.modelType')" prop="modelType"
							:label-width="labelWidth">
							<dsn-input v-model.trim="equipmentForm.modelType"
								:placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('equipments.fields.modelType')"
								:disabled="isNewGear==false"></dsn-input>
						</el-form-item>
					</el-col>
				</el-row>
				<!--            <el-form-item :label="this.$t('equipments.fields.sno')" prop="sno" :label-width="labelWidth">-->
				<!--              <dsn-input-->
				<!--                  v-model.trim="equipmentForm.sno"-->
				<!--                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('equipments.fields.sno')"-->
				<!--              ></dsn-input>-->
				<!--            </el-form-item>-->


				<!--            <el-form-item :label="this.$t('equipments.fields.sapNo')" prop="sapAssetNo" :label-width="labelWidth">-->
				<!--              <dsn-input-->
				<!--                  v-model.trim="equipmentForm.sapAssetNo"-->
				<!--                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('equipments.fields.sapNo')"-->
				<!--              ></dsn-input>-->
				<!--            </el-form-item>-->
				<!--          <el-row>-->
				<!--            <el-col :span="8">-->
				<!--              <el-form-item :label="this.$t('equipments.fields.assetNo')" prop="assetNo" :label-width="labelWidth">-->
				<!--                <dsn-input-->
				<!--                    v-model.trim="equipmentForm.assetNo"-->
				<!--                    :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('equipments.fields.assetNo')"-->
				<!--                ></dsn-input>-->
				<!--              </el-form-item>-->
				<!--            </el-col>-->
				<!--          </el-row>-->
				<!--            <el-form-item :label="this.$t('equipment.fields.runState')" prop="runningStatusId"-->
				<!--                          :label-width="labelWidth">-->
				<!--              <dsn-select-->
				<!--                  v-model.trim="equipmentForm.runningStatusId"-->
				<!--                  filterable-->
				<!--                  style="width:225px"-->
				<!--                  :placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.runState')"-->
				<!--                  size="medium"-->
				<!--              >-->
				<!--                <el-option-->
				<!--                    v-for="item in runningSateList"-->
				<!--                    :key="item.key"-->
				<!--                    :label="item.label"-->
				<!--                    :value="item.value"-->
				<!--                ></el-option>-->
				<!--              </dsn-select>-->
				<!--            </el-form-item>-->
				<el-row>

					<el-col :span="8">
						<el-form-item label="机身编码" prop="sno" :label-width="labelWidth">
							<dsn-input v-model.trim="equipmentForm.sno" placeholder="请输入机身编码"></dsn-input>
						</el-form-item>
					</el-col>

					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.acquisitionDate')" prop="acquisitionDate"
							:label-width="labelWidth">
							<el-date-picker v-model.trim="equipmentForm.acquisitionDate" type="datetime"
								:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.acquisitionDate')"
								size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.factory')" prop="factoryName"
							:label-width="labelWidth">
							<dsn-select v-model.trim="equipmentForm.factoryName" filterable style="width:225px"
								:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.factory')"
								size="medium">
								<el-option v-for="item in factoryList" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>

				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.storage')" prop="storageId"
							:label-width="labelWidth">
							<!--              <storage-tree-select v-model="equipmentForm.storageId" :data="storageNodeList" :node-key="'id'"/>-->
							<el-tree-select ref="treeSelect" v-model="equipmentForm.storageId"
								:selectParams="selectParams" :treeParams="treeParams"
								v-on:node-click="onStorageNodeClick" :isSingle="true" />
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.equipmentCondition')" prop="conditionId"
							:label-width="labelWidth">
							<dsn-select v-model.trim="equipmentForm.conditionId" filterable style="width:225px"
								:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.equipmentCondition')"
								size="medium">
								<el-option v-for="item in conditionList" :key="item.key" :label="item.label"
									:value="item.value"></el-option>
							</dsn-select>
						</el-form-item>
					</el-col>

					<el-col :span="8">
						<el-form-item :label="this.$t('equipments.fields.images')" :label-width="labelWidth">
							<el-upload class="upload-demo" style="display: flex;" ref="uploadpic" :auto-upload="false"
								action="uploadFileURL" :on-change="fileChange" :before-upload="picBefore"
								:on-remove="filesremove" :on-success="filesSuccess" :file-list="fileList" :http-request="handleUplodFile"
								list-type="picture-card" multiple>
								<!-- <el-button size="small" type="primary">{{this.$t('commmsg.upload')}}</el-button> -->
								
								<!-- <i slot="default" class="el-icon-plus"></i>
								    <div slot="file" slot-scope="{file}">
								      <img
								        class="el-upload-list__item-thumbnail"
								        :src="file.url" alt=""
								      >
								</div> -->
								
								<!-- <div style="margin:0 0 0 20px;">
									<span>{{isFile.name}}</span>
								</div> -->
								
								<i slot="default" class="el-icon-plus"></i>
										<div slot="file" slot-scope="{ file }" style="width: 100%;height: 100%;">
											
											<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								                <span class="el-upload-list__item-actions">
								                  <span
								                    class="el-upload-list__item-preview"
								                    @click="Previewpic(file)"
								                  >
								                    <i class="el-icon-zoom-in"></i>
								                  </span>
								                  
								                  <span
								                    class="el-upload-list__item-delete"
								                    @click="removepic(file)"
								                  >
								                    <i class="el-icon-delete"></i>
												</span>
											</span>
										</div>
								
								
								<!-- :action="uploadFileURL":http-request="handleUplodFile":action="`https://cpm.deartsz.com/api/equipments/${getRecordID}/uploadfiles`"<div style="display: flex;justify-content: space-around;height: 100%;">
					<div class="uploadTable" style="width: 60%;margin: auto 0;height: 150px;overflow: hidden;overflow-y: scroll;">
						<el-table :empty-text="$t('header.tableEmpty')" :data="fileList" stripe :show-header="false" style="width: 100%">
							<el-table-column prop="name">
								<template slot-scope="scope">
									<span style="margin-right: 10px;font-size:15px;">{{ scope.row.name }}</span>
									<i class="el-icon-circle-check" style="color:#67C23A;"></i>
								</template>
							</el-table-column>
							<el-table-column prop="download">
								<template slot-scope="scope">
									<el-link type="primary" icon="el-icon-download" style="font-size:15px;" @click.stop="clickFiles(scope.row)">
										{{ $t('GoodsList.dialog[12]') }}
									</el-link>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div> -->

							</el-upload>
							
							<el-dialog :visible.sync="dialogVisible">
									<img width="100%" :src="dialogImageUrl" alt="预览图片" />
								</el-dialog>
							
							
							
							
							
							
							
						</el-form-item>
					</el-col>

					<!-- profile_id -->


				</el-row>

				<!--          <el-col>-->
				<!--            <el-form-item :label="this.$t('equipment.fields.dept')" prop="deptId" :label-width="labelWidth">-->
				<!--              <dsn-input-->
				<!--                  v-model.trim="equipmentForm.deptId"-->
				<!--                  placeholder="请选择使用部门"-->
				<!--              ></dsn-input>-->
				<!--            </el-form-item>-->
				<!--            <el-form-item :label="this.$t('equipment.fields.user')" prop="userId" :label-width="labelWidth">-->
				<!--              <dsn-input-->
				<!--                  v-model.trim="equipmentForm.userId"-->
				<!--                  placeholder="选择使用人"-->
				<!--              ></dsn-input>-->
				<!--            </el-form-item>-->
				<!--          </el-col>-->
				<!--          <el-col>-->
				<!--            <el-form-item :label="this.$t('equipment.fields.directorDept')" prop="directorDeptId"-->
				<!--                          :label-width="labelWidth">-->
				<!--              <dsn-input-->
				<!--                  v-model.trim="equipmentForm.directorDeptId"-->
				<!--                  placeholder="请选择使用部门"-->
				<!--              ></dsn-input>-->
				<!--            </el-form-item>-->
				<!--            <el-form-item :label="this.$t('equipment.fields.director')" prop="directorId" :label-width="labelWidth">-->
				<!--              <dsn-input-->
				<!--                  v-model.trim="equipmentForm.directorId"-->
				<!--                  placeholder="选择使用人"-->
				<!--              ></dsn-input>-->
				<!--            </el-form-item>-->
				<!--          </el-col>-->
			</el-form>
		</div>
	</div>

</template>
<script>
	import {
		mapState
	} from 'vuex';
	import {
		addEquipments,
		GetMainProfileList,
		uploadfilesapi
	} from "@/api/efms/equipments/equipments.api";
	import {
		getBrandList
	} from "@/api/efms/equipments/equipment.brand.api";
	import {
		getEquipmentCategoryList
	} from "@/api/efms/equipments/equipment.category.api";
	import arrayToTree from "array-to-tree";
	import {
		getStorageList
	} from "@/api/efms/equipments/equipment.storage.api";
	import StorageTreeSelect
	from "@/views/mes/basic_settings/equipments_position_ref_settings/components/storage-tree-select";

	export default {
		name: 'EquipmentAdd',
		components: {
			StorageTreeSelect
		},
		data() {
			const valiIP = (rule, value, callback) => {
				if (value === "") {
					callback("IP地址不能为空");
				}
				let reg = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
				if (!reg.test(value)) {
					callback("IP地址格式错误");
				}
				callback();
			}
			return {
				isEditStation: false, //是否可编辑站位
				equipmentForm: {
					equipmentMainCategoryId: null,
					equipmentMainCategoryName: null,
					equipmentCategoryId: null,
					equipmentCategoryName: null,
					equipmentSubcategoryId: null,
					equipmentSubcategoryName: null,
					assetNo: null,
					sapAssetNo: null,
					name: null,
					modelType: null,
					acquisitionDate: null,
					userId: null,
					deptId: null,
					directorDeptId: null,
					directorId: null,
					conditionId: null,
					conditionDesc: null,
					storageId: null,
					runningStatusId: null,
					sapAcceptStatus: null,
					sno: null,
					brandName: null,
					factoryName:null,
				},
				isNewGear: false,
				mianfileID:'',
				maingear: '',
				MainProfileList: [],
				fileList: [],
				uploadData: {},
				attachList: [],
				isFile: '',
				getRecordID: '',
				dialogImageUrl:'',
				dialogVisible:false,
				// uploadURL:'https://cpm.deartsz.com/api/equipments/'+this.getRecordID+'/uploadfiles',
				// uploadFileURL: '',
				realUploadFile: [],
				selectParams: {
					multiple: false,
					clearable: true,
					placeholder: `${this.$t('commmsg.pleaseSelect') + ' ' + this.$t('equipments.fields.storage')}`

				},
				treeParams: {
					clickParent: true,
					filterable: false,
					'check-strictly': true,
					'default-expand-all': true,
					'expand-on-click-node': false,
					data: [],
					props: {
						children: 'children',
						label: 'position',
						// disabled: 'disabled',
						value: 'id'
					}
				},
				labelWidth: "130px",
				categoryFullList: [],
				categoryFullNodeList: [],
				equipmentCategoryList: [],
				equipmentSubcategoryList: [],
				conditionList: [],
				mainDeptList: [],
				brandList: [],
				storageNodeList: [],
				storageFullList: [],
				mainCategoryList: [],
				runningSateList: [{
						label: '开机',
						value: 1,
					},
					{
						label: '关机',
						value: 0,
					}, {
						label: '其他',
						value: 2,
					}
				],
				factoryList: [
					{
					label: '西丽工厂',
					value: '西丽工厂'
				},
				{
					label: '东莞工厂',
					value: '东莞工厂'
				},
				]
			}
		},
		created() {
			this.operateType = this.$route.query.operateType;
			this.initSelectData();


			this.initDate();
		},

		computed: mapState({
			
			
			
			




			uploadFileURL() {
				return 'https://cpm.deartsz.com/api/Utils/UploadAttachment?biz_type=equipment&id='+this.getRecordID;
				// return `https://cpm.deartsz.com/api/equipments/${this.getRecordID}/uploadfiles`;
				// return "https://cpm.deartsz.com/api/equipments/"+this.getRecordID+"/uploadfiles"
				// return uploadfilesapi(this.getRecordID);
			},

			userFormRules: function() {
				let that = this
				return {
					equipmentCategoryId: [{
						required: true,
						message: "请输入中分类",
						trigger: "change"
					}],
					equipmentSubcategoryId: [{
						required: true,
						message: "请输入小分类",
						trigger: "change"
					}],
					brandName: [{
						required: false,
						message: "请选择品牌",
						trigger: "change"
					}],
					name: [{
						required: false,
						message: "请输入设备名称",
						trigger: "change"
					}],
					sno: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.sno')}`,
						trigger: "change"
					}],
					modelType: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.modelType')}`,
						trigger: "change"
					}],
					deptId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.dept')}`,
						trigger: "change"
					}],
					userId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.user')}`,
						trigger: "change"
					}],
					directorDeptId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.directorDept')}`,
						trigger: "change"
					}],
					directorId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.director')}`,
						trigger: "change"
					}],
					conditionId: [{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('equipment.fields.condition')}`,
						trigger: "change"
					}],
				}
			}

		}),
		methods: {
			
			removepic(file,fileList) {
				console.log(file);
			        let uploadFiles = this.$refs.uploadpic.uploadFiles
			        for (var i = 0; i < uploadFiles.length; i++) {
			          if (uploadFiles[i]['url'] == file.url) {
			            uploadFiles.splice(i, 1)
			          }
			        }
				},
				// 点击文件列表中已上传的文件时的钩子
				Previewpic(file) {
			        this.dialogImageUrl = file.url
			        this.dialogVisible = true
				},
				
				
			// 要上传文件压入数组
			handleUplodFile(file) {
				console.log(file);
				this.realUploadFile.push(file.file)
			},
			// 点击保存
			handleHttpUpload() {
				console.log('--------------------------');
				// this.$refs.uploadpic.submit()
				// uploadfilesapi(this.getRecordID);
				let uploadFiles = this.$refs.uploadpic.uploadFiles
				console.log(uploadFiles);
				// console.log(this.isFile);
				
				uploadFiles.forEach(obj=>{
					let formData = new FormData();
					formData.append("file", obj.raw);
					
					// this.$axios({  //这种格式，是需要每个参数都弄成对象的
					//         methods: 'POST',
					//         url: `https://cpm.deartsz.com/api/equipments/${this.getRecordID}/uploadfiles`,
					//         data:this.isFile,
					//         headers:{'Content-Type':'multipart/form-data'},
					//       })
					
					// this.$axios.post('http://161.117.34.230:8101/api/tickets/d6436f56990d4155bd4b382f83a3321f/uploadfiles', formData,{"Content-Type": "multipart/form-data;charset=utf-8"}).then(res => {
					// 		console.log(res);
					// 	}),
					this.$axios.post(this.uploadFileURL, formData,{"Content-Type": "multipart/form-data;charset=utf-8"}).then(res => {
							console.log(res);
						}),
						// this.$refs.uploadpic.submit()
					
						// const formData = new FormData();
						// formData.append("file", this.isFile);
						// // ↓后端请求接口↓
						// uploadfilesapi(formData)
					
					
						//  this.$refs['upload'].submit();
					
						this.$nextTick(() => {
							this.realUploadFile = []
						})
						
				})
				
				
					
				
			},
			// UploadUrl(getNO) {
			// 	// that.getRecordID = data.data.asset_no;
			// 	// this.uploadURL = ""
			// 	// that.$forceUpdate();
			// 	// setTimeout(()=>{
			// 	console.log(getNO);
			// 	var tempURL = '';
			// 	tempURL = "https://cpm.deartsz.com/api/equipments/" + getNO + "/uploadfiles";
			// 	console.log(tempURL);
			// 	// console.log(this.uploadURL);
			// 	// this.$forceUpdate();
			// 	return tempURL;
			// },
			picBefore(file) {
				console.log(file)
				if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
					this.notification().error({
						body: this.$t('commmsg.picerr')
					});
					return false;
				}
				else{
					// this.$refs.uploadpic.action = this.uploadFileURL;
					// console.log(this.$refs.uploadpic);
					// this.$nextTick(()=>{
					     // this.$refs.uploadpic.submit();
					  // })
				// 	return new Promise((resolve) => {
				// 		 this.$nextTick(() => {
				// 		 this.uploadURL = "https://cpm.deartsz.com/api/equipments/"+this.getRecordID+"/uploadfiles"; 
				// 		   resolve();
				// 		 })
				// 	 })
				}


			},
			fileChange(file, fileList) {
				console.log(file, fileList);

				if (fileList.length > 5) {
					fileList.splice(0, 1);
				}
				this.isFile = file.raw;
				
				// this.$refs.uploadpic.action = this.uploadFileURL;
				// else{
				//  this.isFile = file.raw;
				// }

			},
			filesSuccess(res, file, fileList) {
				console.log(res, file, fileList);
				this.attachList.push(file.response.data);
				this.attachList = this.attachList;
			},

			filesremove(file, fileList) {
				// console.log(file, fileList);
				this.attachList = fileList.slice(-3);
			},

			onmaingearSelect(e) {
				console.log(e);
				this.MainProfileList.forEach(obj => {
					if (obj.id == e) {
						// this.onMiddleCategoryChange(obj.equipment_category_id);
						
						this.equipmentForm.name = obj.name;
						this.equipmentForm.brandName = obj.brand_name;
						this.equipmentForm.modelType = obj.model_type;
						this.mianfileID = obj.id;
						
						this.equipmentForm.equipmentMainCategoryId = obj.equipment_main_category_id;
						this.equipmentForm.equipmentMainCategoryName = obj.equipment_main_category_name;
						
						this.equipmentForm.equipmentCategoryId = obj.equipment_category_id;
						this.equipmentForm.equipmentCategoryName = obj.equipment_category_name;
						
						
						this.equipmentForm.equipmentSubcategoryId = obj.equipment_subcategory_id;
						this.equipmentForm.equipmentSubcategoryName = obj.equipment_subcategory_name;
						
						this.maingear = obj.name + ' | ' + obj.brand_name + ' | ' + obj.model_type
						
						this.onMiddleCategoryChange1(obj.equipment_category_id);
						
					}
				})
			},

			GetMainProfileList() {
				const data = {

					mainCategory_id: this.equipmentForm.equipmentMainCategoryId,
					subCategory_id: this.equipmentForm.equipmentSubcategoryId,
					category_id: this.equipmentForm.equipmentCategoryId,

					isTree: false,
				};
				GetMainProfileList(data).then(response => {
					const {
						status,
						errMsg,
						data
					} = response;
					if (status === 200) {
						this.maingear = '';
						if (data != null && data.data.length > 0) {
							let dataList = data.data;
							if (dataList != null && dataList.length > 0) {
								this.MainProfileList = [...dataList];
							} else {
								this.MainProfileList = [];
							}
							this.$forceUpdate();
						} else {
							this.MainProfileList = [];
							this.$forceUpdate();
						}
					} else {
						this.notification().warn({
							body: errMsg
						});
					}
				});
			},

			getDate() {
				var year = new Date().getFullYear();
				var month = (new Date().getMonth() + 1) >= 10 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1);
				var day = new Date().getDate() >= 10 ? new Date().getDate() : '0' + new Date().getDate();
				return year + "-" + month + "-" + day;
			},
			initDate() {
				this.equipmentForm.acquisitionDate = this.getDate()
				console.log(this.equipmentForm.acquisitionDate);
			},

			handleBack() {
				this.$router.pushAndClear({
					name: "equipmentList"
				}, {
					deleteItem: this.$route,
					current: this.$route,
				});
			},
			checkAdd(formName) {
				let count = 0;
				formName.forEach((element) => {
					this.$refs[element].validate((valid) => {
						if (valid) {
							count++;
						} else {
							return false;
						}
					});
				});
				if (count >= 1) {
					this.messageBox({
							type: "success",
							title: "保存",
							content: "是否保存数据?",
						})
						.then(() => {
							this.handleDataSave();
						})
						.catch(() => {
							this.notification().warn({
								body: "已取消保存",
							});
						});
				}
			},
			handleDataSave() {
				var that = this;
				const postData = {};
				postData['equipmentMainCategoryId'] = this.equipmentForm.equipmentMainCategoryId;
				postData['equipmentMainCategoryName'] = this.equipmentForm.equipmentMainCategoryName;
				postData['equipmentCategoryId'] = this.equipmentForm.equipmentCategoryId;
				postData['equipmentCategoryId'] = this.equipmentForm.equipmentCategoryId;
				postData['equipmentCategoryName'] = this.equipmentForm.equipmentCategoryName;
				postData['equipmentSubcategoryName'] = this.equipmentForm.equipmentSubcategoryName
				postData['equipmentSubcategoryId'] = this.equipmentForm.equipmentSubcategoryId
				postData['assetNo'] = this.equipmentForm.assetNo
				postData['sapAssetNo'] = this.equipmentForm.sapAssetNo
				postData['sno'] = this.equipmentForm.sno
				postData['runningStatusId'] = this.equipmentForm.runningStatusId
				if (postData['runningStatusId'] != null) {
					let targetList = this.runningSateList.filter(s => s.value === postData['runningStatusId'])
					if (targetList != null && targetList.length > 0) {
						postData['runningStatusDesc'] = targetList[0].label
					}
				}
				postData['name'] = this.equipmentForm.name
				// postData['factoryName'] = '西丽工厂'
				postData['factoryName'] = this.equipmentForm.factoryName
				postData['modelType'] = this.equipmentForm.modelType
				postData['brandId'] = this.equipmentForm.brandId
				postData['brandName'] = this.equipmentForm.brandName
				postData['acquisitionDate'] = this.equipmentForm.acquisitionDate
				postData['userId'] = this.equipmentForm.userId
				postData['deptId'] = this.equipmentForm.deptId
				postData['directorId'] = this.equipmentForm.directorId
				postData['directorDeptId'] = this.equipmentForm.directorDeptId
				postData['conditionId'] = this.equipmentForm.conditionId
				postData['conditionDesc'] = this.equipmentForm.conditionDesc
				postData['storageId'] = this.equipmentForm.storageId
				
				postData['profile_id'] = this.isNewGear == true?0:this.mianfileID
				
				
				if (this.equipmentForm.storageId != null && this.storageFullList.length > 0) {
					let targetList = this.storageFullList.filter(s => s.id === this.equipmentForm.storageId)
					console.info(targetList)
					if (targetList != null && targetList.length === 1) {
						postData['storageName'] = targetList[0].equipmentStorageName
					}
				}
				// postData['storageName'] = this.equipmentForm.storageName;
				console.info("postData", postData)
				// console.log(this.uploadFileURL(this.equipmentForm.storageId));
				// this.uploadFileURL(this.equipmentForm.storageId);
				addEquipments(postData).then(async result => {
					const {
						status,
						data
					} = result;
					if (status === 200 && data.resultCode === 0) {
						console.log(data);
						that.getRecordID = data.data.id;
						// this.uploadURL = "https://cpm.deartsz.com/api/equipments/"+data.data.asset_no+"/uploadfiles"
						that.$forceUpdate();

						// console.log(this.getRecordID);
						// console.log(this.uploadURL);

						// this.UploadUrl(data.data.asset_no);
						// this.$nextTick(() => {
						// 	that.$refs.uploadpic.submit();
						// });
						// setTimeout(()=> {
						//         that.$refs.uploadpic.submit();
						//       }, 400)


						that.notification().success({
							body: `${that.$t('commmsg.addSuccess')}`,
						});
						that.handleBack();
					} else {
						that.notification().error({
							body: data.errMsg,
						});
					}

					console.log(this.uploadFileURL);
					this.handleHttpUpload();
					
					
					
					
					
					
					// this.picBefore().then( (res)=>{  that.$refs.uploadpic.submit() } )
					// setTimeout(()=> {
					//         that.axiosuploaad();
					//       }, 400)
					// this.$nextTick(function () {

					// })
					// that.$refs.uploadpic.action = this.uploadFileURL;
					// console.log(that.$refs.uploadpic.action);
					// setTimeout(()=> {
					//         that.$refs.uploadpic.submit();
					//       }, 400)
					// this.$nextTick(function () {
						// that.$refs.uploadpic.submit();
					// })
					// that.$refs.uploadpic.submit();
					// await that.$refs.uploadpic.submit();
				})
			},
			// axiosuploaad(){
			// 	this.$refs.uploadpic.submit();
			// },
			handleReset(formName) {
				// if (this.operateType === "add") {
				// 	formName.forEach((element) => {
				// 		this.$refs[element].resetFields();
				// 	});
					
				// 	this.maingear = '';
				// }
				this.maingear = '';
				this.equipmentForm.equipmentMainCategoryId = null;
				
				this.equipmentForm.equipmentMainCategoryId = null;
				this.equipmentForm.equipmentMainCategoryName = null;
				this.equipmentForm.equipmentCategoryId = null;
				this.equipmentForm.equipmentCategoryName = null;
				this.equipmentForm.equipmentSubcategoryId = null;
				this.equipmentForm.equipmentSubcategoryName = null;
				this.equipmentForm.assetNo = null;
				this.equipmentForm.sapAssetNo = null;
				this.equipmentForm.name = null;
				this.equipmentForm.modelType = null;
				this.equipmentForm.acquisitionDate = null;
				this.equipmentForm.userId = null;
				this.equipmentForm.deptId = null;
				this.equipmentForm.directorDeptId = null;
				this.equipmentForm.directorId = null;
				this.equipmentForm.conditionId = null;
				this.equipmentForm.conditionDesc = null;
				this.equipmentForm.storageId = null;
				
				this.equipmentForm.runningStatusId = null;
				this.equipmentForm.sapAcceptStatus = null;
				this.equipmentForm.sno = null;
				this.equipmentForm.brandName = null;
				this.equipmentForm.factoryName = null;
				
			},
			initSelectData() {
				this.equipmentForm.factoryName = this.factoryList[0].value;
				this.initCategoryList();
				this.initBrandList();
				this.initConditionList();
				this.initStorageList();
			},
			initCategoryList() {
				let postData = {
					page: 0,
					pageSize: 0,
				}
				getEquipmentCategoryList(postData).then(res => {
					const {
						status,
						data
					} = res;
					if (status === 200 && data != null && data.data != null && data.data.length > 0) {
						this.categoryFullList = [...data.data];
						this.categoryFullNodeList = arrayToTree(this.categoryFullList, {
							parentProperty: 'parentId',
							childrenProperty: 'children',
						})
						console.info(this.categoryFullNodeList);
						let tempList = data.data.filter(v => v.parentId === null || v.parentId === "")
							.map((item) => {
								return {
									label: item.categoryName,
									value: item.id,
								}
							})
						this.mainCategoryList = [...tempList];
						if (this.mainCategoryList.length > 0) {
							// this.equipmentForm.equipmentMainCategoryId=this.mainCategoryList
							let targets = this.mainCategoryList.filter(s => s["label"] === '生产设备')
							if (targets != null && targets.length > 0) {
								console.info('target')
								this.equipmentForm.equipmentMainCategoryId = targets[0].value;
								this.onMainCategoryChange(this.equipmentForm.equipmentMainCategoryId)
							} else {
								this.equipmentForm.equipmentMainCategoryId = null;
							}
							console.info(this.mainCategoryList)
						}
						// this.equipmentCategoryList = [...tempList]
					} else {
						this.mainCategoryList = [];
						// this.equipmentCategoryList = []
					}
				})
			},
			onMainCategoryChange(value) {
				if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList !=
					null && this.categoryFullNodeList.length > 0) {
					let targetItem = this.categoryFullList.filter(s => s.id === value);
					let targetNodeList = this.categoryFullNodeList.filter(s => s.id === value);
					if (targetItem && targetNodeList) {
						let temp = targetNodeList[0].children === null ? [] : targetNodeList[0].children
						if (temp != null && temp.length > 0) {
							this.equipmentForm.equipmentMianCategoryNmae = targetItem[0].categoryName;
							this.equipmentCategoryList = temp.map((v) => {
								return {
									label: v.categoryName,
									value: v.id,
								}
							})
						}
					} else {
						this.equipmentCategoryList = []
					}
				} else {
					this.equipmentCategoryList = []
				}

				this.GetMainProfileList();
			},
			onMiddleCategoryChange(value) {
				if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList !=
					null && this.categoryFullNodeList.length > 0) {
						console.log(value);
						console.log(this.categoryFullList);
					let targetItem = this.categoryFullList.filter(s => s.id === value);
					if (targetItem) {
						console.log(targetItem);
						let parentId = targetItem[0].parentId;
						if (parentId != null) {
							let mainNodeList = this.categoryFullNodeList.filter(s => s.id === parentId);
							if (mainNodeList != null && mainNodeList.length > 0) {
								console.info(mainNodeList)
								let categoryNodeList = mainNodeList[0].children
								if (categoryNodeList != null && categoryNodeList.length > 0) {
									let middleCategoryNodes = categoryNodeList.filter(s => s.id === value); //中分类
									if (middleCategoryNodes != null && middleCategoryNodes.length === 1) {
										this.equipmentForm.equipmentCategoryName = middleCategoryNodes[0].categoryName;
										let subcategoryChildren = middleCategoryNodes[0].children;
										if (subcategoryChildren != null && subcategoryChildren.length > 0) {
											this.equipmentSubcategoryList = subcategoryChildren.map((v) => {
												return {
													label: v.categoryName,
													value: v.id,
												}
											})
										}
									}
								}
							}
						}
					} else {
						this.equipmentSubcategoryList = []
					}
				} else {
					this.equipmentSubcategoryList = []
				}
				this.equipmentForm.equipmentSubcategoryName = null;
				this.equipmentForm.equipmentSubcategoryId = null;
				this.GetMainProfileList();
			},
			onMiddleCategoryChange1(value) {
				if (this.categoryFullList != null && this.categoryFullList.length > 0 && this.categoryFullNodeList !=
					null && this.categoryFullNodeList.length > 0) {
					let targetItem = this.categoryFullList.filter(s => s.id === value);
					if (targetItem) {
						let parentId = targetItem[0].parentId;
						if (parentId != null) {
							let mainNodeList = this.categoryFullNodeList.filter(s => s.id === parentId);
							if (mainNodeList != null && mainNodeList.length > 0) {
								console.info(mainNodeList)
								let categoryNodeList = mainNodeList[0].children
								if (categoryNodeList != null && categoryNodeList.length > 0) {
									let middleCategoryNodes = categoryNodeList.filter(s => s.id === value); //中分类
									if (middleCategoryNodes != null && middleCategoryNodes.length === 1) {
										this.equipmentForm.equipmentCategoryName = middleCategoryNodes[0].categoryName;
										let subcategoryChildren = middleCategoryNodes[0].children;
										if (subcategoryChildren != null && subcategoryChildren.length > 0) {
											this.equipmentSubcategoryList = subcategoryChildren.map((v) => {
												return {
													label: v.categoryName,
													value: v.id,
												}
											})
										}
									}
								}
							}
						}
					} else {
						this.equipmentSubcategoryList = []
					}
				} else {
					this.equipmentSubcategoryList = []
				}
			},
			onSubcategorySelect(value) {
				if (this.equipmentSubcategoryList != null && this.equipmentSubcategoryList.length > 0) {
					let targetItem = this.equipmentSubcategoryList.filter(s => s.value === value);
					// if (targetItem != null && targetItem.length > 0) {
					// 	this.equipmentForm.equipmentSubcategoryName = targetItem[0].label
					// } else {
					// 	this.equipmentForm.equipmentSubcategoryName = null;
					// 	this.equipmentForm.equipmentSubcategoryId = null;
					// }
					// console.log(targetItem);
					if (targetItem != null && targetItem.length > 0) {
						this.equipmentForm.equipmentSubcategoryName = targetItem[0].label;
						this.equipmentForm.equipmentSubcategoryId = targetItem[0].value;
					} else {
						this.equipmentForm.equipmentSubcategoryName = null;
						this.equipmentForm.equipmentSubcategoryId = null;
					}
				}

				this.GetMainProfileList();
			},
			initSubcategory(categoryId) {

			},
			initBrandList() {
				let postData = {
					page: 0,
					pageSize: 0
				}
				getBrandList(postData).then(result => {
					const {
						status,
						data
					} = result;
					if (status === 200 && data.data != null) {
						if (data.data.length > 0) {
							let tempList = data.data.map((item) => {
								return {
									label: item.brandName,
									id: item.id,
									value: item.id,
								}
							})
							this.brandList = [...tempList]
						} else {
							this.brandList = []
						}
					}
				})
			},
			initStorageList() { //获取主分类下拉框
				let postData = {
					page: 0,
					pageSize: 0
				}
				getStorageList(postData).then(res => {
					const {
						status,
						data
					} = res
					if (status != null && data != null && data.data.length > 0) {
						this.storageFullList = [...data.data]
						let tempList = data.data.map(v => {
							return {
								position: v.equipmentStorageName,
								id: v.id,
								costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
								parentId: v.parentId == null || v.parentId === "" ? null : v.parentId,
								children: null,
								productionAreaFlag: v.productionAreaFlag
							}
						})
						let resultList = arrayToTree(tempList, {
							parentProperty: 'parentId',
							childrenProperty: 'children'
						})
						if (resultList.length > 0) {
							resultList.forEach(item => {
								item.disabled = true;
								let nodeChildren = item.children;
								if (nodeChildren != null && nodeChildren.length > 0) {
									nodeChildren.forEach(secItem => {
										if (secItem != null && secItem.children != null && secItem
											.children.length > 0) {
											secItem.disabled = true;
											secItem.children.forEach(third => {
												Object.assign(third, {
													disabled: false
												})
											})
										}
									})
								}
							})
						}
						this.treeParams.data = [...resultList]
						this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
						this.storageNodeList = [...resultList]
					} else {
						this.storageNodeList = []
						this.treeParams.data = []
						this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
					}
				})
			},
			initConditionList() {
				this.conditionList.push({
					id: '1',
					label: '使用中',
					value: '1',
					key: 'ok'
				}, {
					id: '2',
					label: '闲置',
					value: '2',
					key: 'free'
				}, {
					id: '3',
					label: '淘汰',
					value: '3',
					key: 'scrap'
				}, {
					id: '4',
					label: '报废',
					value: '4',
					key: 'eliminate'
				})
			},
			onBrandChange(value) {
				console.info(value)
				if (this.brandList != null && this.brandList.length > 0) {
					let targetItem = this.brandList.filter(s => s.value === value)
					if (targetItem != null && targetItem.length === 1) {
						this.equipmentForm.brandName = targetItem[0].label;
					} else {
						this.equipmentForm.brandId = null;
						this.equipmentForm.brandName = null;
					}
				}
			},
			onStorageNodeClick(e) {
				console.info(e)
				const {
					productionAreaFlag
				} = e;
				if (productionAreaFlag === 1) {
					this.equipmentForm.conditionId = "1";
				} else {
					this.equipmentForm.conditionId = null;
				}
			}
		},
		// computed: {
		// 	uploadUrl() {
		// 		return `https://cpm.deartsz.com/api/equipments/${this.getRecordID}/uploadfiles`;
		// 	}
		// }
	}
</script>
<style lang="scss">
	.equipment-add-container {
		.operate {
			padding: 16px;

			.el-button {
				margin: 0 8px 0 0;
			}
		}

		.info {
			background-color: #ffffff;
			padding: 16px;

			.equipmentFormTop {
				.el-form-item {
					margin: 0 24px 16px 0;
				}
			}

			.equipment-main-form {
				.el-form-item {
					margin: 0 24px 16px 0;

					.el-select {
						width: 320px;
					}

					.el-input {
						width: 320px;
					}
				}
			}

			.el-upload {
				display: flex;
			}
			
			.el-upload-list__item{
				width: 70px;
				height: 70px;
			}
			.el-upload--picture-card{
				width: 70px;
				height: 70px;
				align-items: center;
			}
			.el-icon-plus{
				margin: 0 auto;
			}
		}
	}
</style>